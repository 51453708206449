import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #bf846a;
  color: #fff;
  padding: 40px 0;

  h1 {
    font-size: 42px;
    margin-bottom: 4px;
  }

  p {
    text-transform: uppercase;
    font-size: 17px;
  }
`;

export const Container = styled.div`
  max-width: 700px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;

  header {
    display: flex;
    align-self: center;
    align-items: center;
    margin: 36px 0;
  }

  ul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 15px;
  }
`;

export const Add = styled(Link)`
  font-family: 'Roboto', sans-serif;
  border: 0;
  background: #ffffff;
  display: flex;
  align-items: center;
  text-decoration: none;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  padding: 6px 12px;
  transition: background 0.3s ease, border 0.3s ease;

  svg {
    color: #999999;
    transition: color 0.3s ease;
  }

  strong {
    color: #999999;
    font-weight: 200;
    font-size: 15px;
    margin: 0 5px;
    letter-spacing: -0.5px;
    transition: color 0.3s ease;
  }

  &:hover {
    background: #bf846a;
    border: 1px solid #bf846a;

    svg {
      color: #ffffff;
    }

    strong {
      color: #ffffff;
    }
  }
`;

export const Edit = styled(Link)`
  font-family: 'Roboto', sans-serif;
  border: 0;
  background: #bf846a;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  width: 100px;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.9;
  }

  strong {
    color: #ffffff;
    text-transform: uppercase;
    line-height: 24px;
    font-weight: 200;
    font-size: 14px;
    margin: 0 5px;
  }
`;

export const Delete = styled.button`
  font-family: 'Roboto', sans-serif;
  border: 0;
  background: #999999;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  width: 100px;
  transition: opacity 0.3s ease;
  margin-left: 10px;

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }

  strong {
    color: #ffffff;
    text-transform: uppercase;
    line-height: 24px;
    font-weight: 200;
    font-size: 14px;
    margin: 0 5px;
  }
`;

export const PostInfo = styled.li`
  padding: 20px 30px;
  border-radius: 4px;
  background: #fff;
  border: 1px solid #e7e7e7;
  display: flex;
  justify-content: space-between;

  background: ${props => (props.notAvailable ? '#f9f9f9' : '#FFF')};

  div.description {
    width: 70%;
    display: flex;
    flex-direction: column;

    strong {
      display: block;
      color: ${props => (props.notAvailable ? '#999' : '#e49b86')};
      opacity: ${props => (props.notAvailable ? 0.6 : 1)};
      font-size: 18px;
      font-weight: normal;
      margin-bottom: 10px;
    }

    span {
      display: flex;
      align-items: center;
      margin-top: 3px;
      color: ${props => (props.notAvailable ? '#999' : '#666')};
      opacity: ${props => (props.notAvailable ? 0.6 : 1)};

      .date {
        margin: 0 0 0 4px;
      }
    }
  }

  div.controls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

export const ModalBackground = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
`;

export const Modal = styled.div`
  width: 400px;
  background: #fff;
  border-radius: 4px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 16px;
    margin-bottom: 20px;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    button.cancel {
      background: #999;
    }

    button.confirm {
      background: #bf846a;
    }

    button {
      font-family: 'Roboto', sans-serif;
      border: 0;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 12px;
      width: 100px;
      transition: opacity 0.3s ease;
      color: #ffffff;
      text-transform: uppercase;
      line-height: 24px;
      font-weight: 200;
      font-size: 14px;
      margin: 0 5px;

      &:hover {
        opacity: 0.9;
      }
    }
  }
`;
