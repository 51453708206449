import React, { useState, useEffect, useCallback } from 'react';
import { MdNoteAdd, MdDelete, MdDateRange, MdModeEdit } from 'react-icons/md';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/core';
import { toast } from 'react-toastify';

import api from '~/services/api';

import {
  Title,
  Container,
  Add,
  Edit,
  Delete,
  PostInfo,
  ModalBackground,
  Modal,
} from './styles';

const override = css`
  display: block;
  margin: 0 auto;
  position: absolute;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
`;

export default function Admin() {
  const [postPreview, setPost] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [postToBeDeleted, setPostToBeDeleted] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadPosts() {
      const response = await api.get('posts');
      const { data } = response;

      setPost(data);
    }

    loadPosts();
  }, []);

  const handleModalOpen = useCallback(
    post => {
      setShowModal(true);
      setPostToBeDeleted(post);
    },
    [setShowModal, setPostToBeDeleted]
  );

  const handlePostDelete = useCallback(
    async id => {
      setLoading(true);

      try {
        await api.delete(`posts/${id}`);

        const updatedPosts = postPreview.filter(post => post._id !== id);

        setPost([...updatedPosts]);

        toast.success('Post excluído com sucesso!');
      } catch (err) {
        toast.error('Falha ao excluir, tente novamente.');
      } finally {
        setLoading(false);
        setShowModal(false);
        setPostToBeDeleted();
      }
    },
    [postPreview]
  );

  return (
    <>
      <Title>
        <h1>Bem vinda, Malu!</h1>
        <p>Crie, edite e oculte postagens</p>
      </Title>
      <Container>
        <header>
          <Add to="/admin/post-new">
            <MdNoteAdd size={18} />
            <strong>NOVO POST</strong>
          </Add>
        </header>

        <ul>
          {postPreview.map(post => (
            <PostInfo key={post._id} notAvailable={post.hidden}>
              <div className="description">
                <strong>{post.title}</strong>
                <span>
                  <MdDateRange size={16} color="#666" />{' '}
                  <span className="date">{post.date}</span>
                </span>
                <span>{post.hidden ? 'Oculto' : 'Visível'}</span>
              </div>

              <div className="controls">
                <Edit to={`/admin/post-${post._id}`}>
                  <MdModeEdit size={16} color="#FFF" />
                  <strong>Editar</strong>
                </Edit>

                <Delete type="button" onClick={() => handleModalOpen(post)}>
                  <MdDelete size={16} color="#FFF" />
                  <strong>Excluir</strong>
                </Delete>
              </div>
            </PostInfo>
          ))}
        </ul>
      </Container>

      {showModal && (
        <ModalBackground>
          <Modal>
            <p>
              Você realmente deseja excluir o post &quot;{postToBeDeleted.title}
              &quot;? Essa ação é irreversível.
            </p>

            <div>
              <button
                type="button"
                className="cancel"
                onClick={() => setShowModal(false)}
              >
                Cancelar
              </button>
              <button
                type="button"
                className="confirm"
                onClick={() => handlePostDelete(postToBeDeleted._id)}
              >
                Excluir
              </button>
            </div>

            <ClipLoader
              css={override}
              sizeUnit="px"
              size={40}
              color="#333"
              loading={loading}
            />
          </Modal>
        </ModalBackground>
      )}
    </>
  );
}
