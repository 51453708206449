import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Wrapper, TopArrow, BottomArrow } from './styles';

function HamburgerButton({ onToggle, menu }) {
  return (
    <Wrapper onClick={onToggle}>
      <TopArrow menuOpen={menu.menuOpen} />
      <BottomArrow menuOpen={menu.menuOpen} />
    </Wrapper>
  );
}

HamburgerButton.propTypes = {
  onToggle: PropTypes.func.isRequired,
  menu: PropTypes.shape({
    menuOpen: PropTypes.bool,
    section: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(state => ({
  menu: state.menu,
}))(HamburgerButton);
