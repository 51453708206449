import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import Admin from '../pages/Admin';
import AdminPost from '../pages/AdminPost';
import Auth from '../pages/Auth';
import Blog from '../pages/Blog';
import Main from '../pages/Main';
import Password from '../pages/Password';
import Post from '../pages/Post';
import Profile from '../pages/Profile';
import FourOFour from '../pages/FourOFour';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Main} />
      <Route path="/blog" component={Blog} />
      <Route path="/post/:id" component={Post} />
      <Route path="/login" component={Auth} isLogin />

      <Route path="/resetpassword" component={Password} isPrivate />
      <Route path="/admin" exact component={Admin} isPrivate />
      <Route path="/admin/:id" component={AdminPost} isPrivate />
      <Route path="/profile" component={Profile} isPrivate />

      <Route path="/" component={FourOFour} />
    </Switch>
  );
}
