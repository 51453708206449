import React, { Component } from 'react';
import PropTypes from 'prop-types';
import api from '~/services/api';
import { Redirect } from 'react-router-dom';
import { css } from '@emotion/core';

import { Form, Input } from '@rocketseat/unform';
import {
  MegadraftEditor,
  editorStateFromRaw,
  editorStateToJSON,
} from 'megadraft';

import {
  Title,
  Container,
  PostImage,
  EditorMalu,
  Save,
  Cancel,
} from './styles';
import '~/../node_modules/megadraft/dist/css/megadraft.css';

import ClipLoader from 'react-spinners/ClipLoader';

const override = css`
  display: block;
  margin: 0 auto;
  position: absolute;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
`;

class AdminPost extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      editorState: editorStateFromRaw(null),
      selectedOption: 'Oculto',
      post: {
        id: null,
        title: null,
        content: null,
        hidden: null,
        imagePath: null,
        url: null,
      },
      result: null,
      redirect: false,
      loading: false,
    };
  }

  handleTitleChange = e => {
    const title = e.target.value;

    this.setState(prevState => ({
      ...prevState.editorState,
      ...prevState.selectedOption,
      post: {
        ...prevState.post,
        title: title,
      },
      ...prevState.result,
      ...prevState.redirect,
    }));
  };

  handleOptionChange = e => {
    const option = e.target.value;

    this.setState(prevState => ({
      ...prevState.editorState,
      selectedOption: option,
      post: {
        ...prevState.post,
        hidden: option === 'Visivel' ? false : true,
      },
      ...prevState.result,
      ...prevState.redirect,
    }));
  };

  handleImageChange = async e => {
    const data = new FormData();

    data.append('file', e.target.files[0]);

    this.setState(prevState => ({
      ...prevState,
      loading: true,
    }));

    let response = null;

    await api
      .post('files', data)
      .then(res => {
        response = res;

        this.setState(prevState => ({
          ...prevState,
          loading: false,
        }));
      })
      .catch(err => {
        console.log(err);
        this.setState(prevState => ({
          ...prevState,
          result: 'Não foi possível carregar a imagem',
          loading: false,
        }));

        return;
      });

    const { url } = response.data;

    const imagePath = url.split('/files/')[1];

    this.setState(prevState => ({
      ...prevState.editorState,
      ...prevState.selectedOption,
      post: {
        ...prevState.post,
        imagePath: imagePath,
        url: url,
      },
      ...prevState.result,
      loading: false,
    }));
  };

  onChange = editorState => {
    this.setState({ editorState });
  };

  onSaveClick = async () => {
    const { match } = this.props;
    let id = match.url.substring(12);

    const { editorState, post } = this.state;
    const content = editorStateToJSON(editorState);

    // Validation for title
    if (!post.title) {
      this.setState(prevState => ({
        ...prevState,
        result: 'Erro: o título precisa ser preenchido, tente novamente.',
      }));
      return;
    }

    // Validation for image
    if (!post.imagePath) {
      this.setState(prevState => ({
        ...prevState,
        result: 'Erro: é preciso ter uma imagem, tente novamente.',
      }));
      return;
    }

    // Validation for content
    if (!editorState.getCurrentContent().hasText()) {
      this.setState(prevState => ({
        ...prevState,
        result: 'Erro: não há conteúdo escrito, tente novamente',
      }));
      return;
    }

    const postData = {
      title: post.title,
      postContent: content,
      hidden: post.hidden,
      image_path: post.imagePath,
    };

    if (id === 'new') {
      await api
        .post('/posts', postData)
        .then(response => {
          this.setState(prevState => ({
            ...prevState,
            result: 'Post criado com sucesso!',
          }));
        })
        .catch(err => {
          this.setState(prevState => ({
            ...prevState,
            result: 'Ops! Parece que houve um erro ao criar o novo post.',
          }));
        })
        .finally(() => {
          setTimeout(() => {
            this.setState(prevState => ({
              ...prevState,
              redirect: true,
            }));
          }, 1000);
        });
    } else {
      await api
        .put(`/posts/${post.id}`, postData)
        .then(response => {
          this.setState(prevState => ({
            ...prevState,
            result: 'Post salvo com sucesso!',
          }));
        })
        .catch(err => {
          this.setState(prevState => ({
            ...prevState,
            result: 'Ops! Parece que houve um erro ao salvar.',
          }));
        })
        .finally(() => {
          setTimeout(() => {
            this.setState(prevState => ({
              ...prevState,
              redirect: true,
            }));
          }, 1000);
        });
    }
  };

  async componentDidMount() {
    const { match } = this.props;
    let id = match.url.substring(12);

    if (id === 'new') {
      this.setState(prevState => ({
        editorState: editorStateFromRaw(null),
        selectedOption: 'Visivel',
        post: {
          ...prevState.post,
          hidden: false,
          url: 'https://ui-avatars.com/api/?name=Malu+Dabronzo',
        },
        result: null,
        redirect: false,
      }));
    } else {
      let postData = await api.get(`/post/${id}`);

      postData = postData.data;

      let post = {
        id: postData._id,
        title: postData.title,
        content: postData.content,
        hidden: postData.hidden,
        imagePath: postData.image_path,
        url: postData.url,
      };

      let option = post.hidden ? 'Oculto' : 'Visivel';

      this.setState(prevState => ({
        editorState: editorStateFromRaw(JSON.parse(JSON.parse(post.content))),
        selectedOption: option,
        post: post,
        ...prevState.result,
        redirect: false,
      }));
    }
  }

  render() {
    const { selectedOption, post, result, redirect, loading } = this.state;

    if (redirect) {
      return <Redirect to="/admin" />;
    }

    return (
      <>
        <Title>
          <h1>Editor</h1>
        </Title>

        <Container result={result}>
          <Form initialData={post}>
            <div className="wrapper">
              <PostImage>
                <label htmlFor="post-image">
                  <img
                    src={
                      post.url ||
                      'https://ui-avatars.com/api/?name=Malu+Dabronzo'
                    }
                    alt="Post cover"
                  />

                  <input
                    type="file"
                    id="post-image"
                    accept="image/*"
                    data-file={post.url}
                    onChange={this.handleImageChange}
                    // ref={ref}
                  />
                </label>

                <ClipLoader
                  css={override}
                  sizeUnit={'px'}
                  size={40}
                  color={'#333'}
                  loading={loading}
                />
              </PostImage>
            </div>

            <div className="wrapper details">
              <label className="title">
                Título do post
                <Input
                  name="title"
                  placholder="Título do post"
                  type="text"
                  onChange={this.handleTitleChange}
                />
              </label>

              <div className="radio-buttons">
                <label>
                  <Input
                    name="hidden"
                    type="radio"
                    value="Visivel"
                    checked={selectedOption === 'Visivel'}
                    onChange={this.handleOptionChange}
                    className="form-check-input"
                  />
                  <span className="checkmark" />
                  <span className="radio-title">Visivel</span>
                </label>

                <label>
                  <Input
                    name="hidden"
                    type="radio"
                    value="Oculto"
                    checked={selectedOption === 'Oculto'}
                    onChange={this.handleOptionChange}
                    className="form-check-input"
                  />
                  <span className="checkmark" />
                  <span className="radio-title">Oculto</span>
                </label>
              </div>
            </div>
          </Form>

          <hr />

          <EditorMalu>
            <MegadraftEditor
              plugins={[]}
              editorState={this.state.editorState}
              onChange={this.onChange}
              sidebarRendererFn={this.getCustomSidebar}
            />
          </EditorMalu>
          <Save onClick={this.onSaveClick}>Salvar</Save>
          <Cancel to="/admin">Cancelar/Descartar</Cancel>
          <p className="result">{result}</p>
        </Container>
      </>
    );
  }
}

export default AdminPost;
