import React, { useState, useEffect } from 'react';
import api from '~/services/api';

import { Container, Content, Header, Posts, PostItem } from './styles';

import Sidebar from '~/components/Sidebar';
import SidebarBlog from '~/components/SidebarBlog';

export default function Blog() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    async function fecthData() {
      const response = await api.get('/posts');
      setPosts(response.data);
    }

    fecthData();
  }, []);

  return (
    <>
      <Container>
        <Content>
          <Header>
            <h1>SUA PELE</h1>
            <p>É sobre pele e saúde. É sobre você.</p>
          </Header>

          <Posts>
            {posts.map(post => {
              if (!post.hidden) {
                return (
                  <PostItem
                    key={post.id}
                    title={post.title}
                    className="box"
                    to={`/post/${post._id}-post`}
                  >
                    <div className="box-img">
                      <img src={post.url} alt="Post" />
                      <div className="square">
                        <span className="line1" />
                        <span className="line2" />
                        <p>ler mais</p>
                      </div>
                    </div>
                    <p className="title">{post.title}</p>
                    <p className="mobile">Ler mais</p>
                  </PostItem>
                );
              }
              return null;
            })}
          </Posts>
        </Content>

        {posts.length > 8 ? <Sidebar /> : <SidebarBlog />}
      </Container>
    </>
  );
}
