import { combineReducers } from 'redux';

import menu from './menu/reducer';
import auth from './auth/reducer';
import user from './user/reducer';
import post from './post/reducer';

export default combineReducers({
  menu,
  auth,
  user,
  post,
});
