/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FaInstagram } from 'react-icons/fa';

import HamburgerButton from '../HamburgerButton';
import Menu from '../Menu';

import { Container, LogoWrapper, Instagram } from './styles';

import LogoSidebar from '../../assets/logos/logo-sidebar.svg';

class Sidebar extends Component {
  static propTypes = {
    menu: PropTypes.shape({
      menuOpen: PropTypes.bool,
      section: PropTypes.string.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    isPost: PropTypes.bool,
  };

  state = {
    menuOpen: false,
  };

  toggleMenu = menuOpen => {
    const { menu, dispatch } = this.props;

    if (menuOpen !== menu.menuOpen) {
      // eslint-disable-next-line prefer-destructuring
      menuOpen = menu.menuOpen;
    }

    dispatch({
      type: 'TOGGLE_MENU',
      menuOpen,
    });

    this.setState({ menuOpen: !menuOpen });
  };

  getScrollBarWidth() {
    let inner = document.createElement('p');
    inner.style.width = '100%';
    inner.style.height = '200px';
    inner.style.opacity = '0';

    let outer = document.createElement('div');
    outer.style.position = 'absolute';
    outer.style.top = '0px';
    outer.style.left = '0px';
    outer.style.visibility = 'hidden';
    outer.style.width = '200px';
    outer.style.height = '150px';
    outer.style.overflow = 'hidden';
    outer.appendChild(inner);

    document.body.appendChild(outer);

    let w1 = inner.offsetWidth;
    outer.style.overflow = 'scroll';

    let w2 = inner.offsetWidth;
    if (w1 === w2) w2 = outer.clientWidth;

    document.body.removeChild(outer);

    let distance = w1 - w2;

    return distance;
  }

  render() {
    const { menuOpen } = this.state;

    return (
      <Container distance={this.getScrollBarWidth} post={this.props.isPost}>
        <Menu />

        <HamburgerButton onToggle={() => this.toggleMenu(menuOpen)} />

        <LogoWrapper>
          <img src={LogoSidebar} alt="Malu Dabronzo Logo" />
        </LogoWrapper>

        <Instagram
          as="a"
          href="https://instagram.com/malu.dabronzo?igshid=u3iei12wfw2v"
          target="_blank"
          onClick={() => this.toggleMenu(menuOpen)}
        >
          <FaInstagram className="instagram" />
        </Instagram>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  menu: state.menu,
});

export default connect(mapStateToProps)(Sidebar);
