import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { device } from '~/styles/device';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  width: 85vw;
  margin: 5vw 0;

  @media ${device.laptop} {
    margin: 2vw 5vw 5vw 0;
  }
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 200;
  margin: 44px 0 34px 0;

  h1 {
    font-size: 24px;
    font-weight: 200;
    letter-spacing: 4px;
  }

  p {
    display: none;
  }

  @media ${device.laptop} {
    justify-content: space-between;
    align-items: baseline;
    margin: 0 0 20px 0;

    h1 {
      margin: 0;
      font-size: 36px;
      font-weight: 200;
      letter-spacing: 4px;
    }

    p {
      display: block;
    }
  }

  @media ${device.desktop} {
    h1 {
      margin: 15px 0;
      font-size: 46px;
    }

    p {
      font-size: 17px;
    }
  }

  @media ${device.desktopL} {
    h1 {
      margin: 26px 0;
      font-size: 60px;
    }

    p {
      font-size: 26px;
    }
  }
`;

export const Posts = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;

  a.box {
    width: 40vw;
    height: 40vw;
    margin: 0 5vw 50px 0;
    align-items: center;
  }

  a.box:nth-child(3n) {
    width: 85vw;
    margin: 0 0 50px 0;

    .box-img .square {
      width: 80vw;
    }
  }

  a.box:nth-child(3n - 1) {
    margin: 0 0 50px 0;
  }

  @media ${device.laptop} {
    a.box {
      width: 15vw;
      height: 15vw;
      margin: 0 2.5vw 50px 0;
      align-items: center;
    }

    a.box:nth-child(3n) {
      width: 15vw;
      margin: 0 2.5vw 50px 0;

      .box-img .square {
        width: 13.5vw;
      }
    }

    a.box:nth-child(3n - 1) {
      margin: 0 2.5vw 50px 0;
    }

    a.box:nth-child(8n) {
      margin: 0 0 50px 0;
    }

    a.box:nth-child(8n - 2) {
      width: 32.5vw;
      margin: 0 2.5vw 50px 0;

      .box-img .square {
        width: 31vw;
      }
    }

    a.box:nth-child(8n - 4) {
      width: 32.5vw;
      margin: 0 0 50px 0;

      .box-img .square {
        width: 31vw;
      }
    }
  }

  @media ${device.desktop} {
    font-size: 17px;
  }

  @media ${device.desktopL} {
    font-size: 26px;
  }
`;

export const PostItem = styled(Link)`
  display: flex;
  flex-direction: column;
  color: #7e7e7e;
  cursor: pointer;

  div.box-img {
    width: 100%;
    height: 100%;
    position: relative;
    transition: opacity 0.3s ease-in-out;
    background: #ffffff;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    }

    &:hover img {
      opacity: 1;
    }

    div.square {
      display: none;

      span {
        width: 1.3vw;
        height: 0.3vw;
        background: #fff;
        display: block;
        opacity: 0;
        transform-origin: center right;
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
      }

      span.line1 {
        margin-bottom: -0.3vw;
        transform: translateY(0vw);
      }

      span.line2 {
        transform: translateY(0vw);
      }

      p {
        position: absolute;
        bottom: 3.5vw;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        color: #ffffff;
      }
    }

    &:hover div.square {
      opacity: 0;

      span {
        opacity: 1;
      }

      span.line1 {
        transform: rotate(45deg) translateY(0.1vw);
        transform-origin: center right;
      }

      span.line2 {
        transform: rotate(-45deg) translateY(-0.1vw);
        transform-origin: center right;
      }
    }
  }

  p.title {
    font-family: 'Montserrat', sans-serif;
    margin-top: 5px;
  }

  p.mobile {
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    color: #b3b3b3;
    font-size: 11px;
  }

  @media ${device.laptop} {
    display: flex;
    flex-direction: column;
    color: #7e7e7e;
    cursor: pointer;

    div.box-img {
      width: 100%;
      height: 100%;
      position: relative;
      transition: opacity 0.3s ease-in-out;
      background: #ffffff;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.3;
        transition: opacity 0.3s ease-in-out;
      }

      &:hover img {
        opacity: 1;
      }

      div.square {
        content: '';
        position: absolute;
        background: rgba(133, 67, 18, 0.5);
        width: 13.5vw;
        height: 90%;
        top: 0.75vw;
        left: 0.75vw;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        span {
          width: 1.3vw;
          height: 0.3vw;
          background: #fff;
          display: block;
          opacity: 0;
          transform-origin: center right;
          transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
        }

        span.line1 {
          margin-bottom: -0.3vw;
          transform: translateY(0vw);
        }

        span.line2 {
          transform: translateY(0vw);
        }

        p {
          position: absolute;
          bottom: 3.5vw;
          font-family: 'Montserrat', sans-serif;
          font-weight: 400;
          color: #ffffff;
        }
      }

      &:hover div.square {
        opacity: 1;

        span {
          opacity: 1;
        }

        span.line1 {
          transform: rotate(45deg) translateY(0.1vw);
          transform-origin: center right;
        }

        span.line2 {
          transform: rotate(-45deg) translateY(-0.1vw);
          transform-origin: center right;
        }
      }
    }

    p.title {
      font-family: 'Montserrat', sans-serif;
      margin-top: 5px;
    }

    p.mobile {
      display: none;
    }
  }
`;
