import styled from 'styled-components';

import { Link } from 'react-router-dom';

import { device } from '../../styles/device';

import MaluLogo from '../../assets/logos/logo-two-lines-black.svg';
import MaluLogoDesktop from '../../assets/logos/logo-one-line-black.svg';

export const Container = styled.div`
  background: #fff;
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: ${props => (props.menuOpen ? '100vh' : '0')};
  transition: height 0.3s ease;
  z-index: 99;

  @media screen and (orientation: landscape) and (min-device-width: 600px) and (max-device-width: 750px) {
    height: ${props => (props.menuOpen ? '200vh' : '0')};
    transition: height 0.3s ease;
    overflow-y: scroll;
  }

  @media ${device.laptop} {
    height: 100vh;
    width: ${props => (props.menuOpen ? '100vw' : '0')};
    transition: width 0.3s ease;
  }
`;

export const Wrapper = styled.div`
  height: 100%;
  padding: 40px 50px;

  section {
    div.logo-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    opacity: 0;
    background: #efd7bf;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 50px 40px;
    height: 100%;

    animation: 0.8s appear forwards;
    animation-delay: 100ms;

    div.division {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-top: 40px;
      align-items: center;
    }
  }

  a {
    font-size: 0.6rem;
    color: #aeaeae;
    text-align: center;
  }

  @media ${device.tablet} {
    section {
      div.logo-wrapper {
        width: 50%;
      }

      div.division {
        padding-top: 160px;
        width: 100%;
      }
    }
  }

  @media ${device.laptop} {
    section {
      flex-direction: row;

      div.logo-wrapper {
        width: 100%;
      }

      div.division {
        width: 100%;
        padding-top: 0;
        justify-content: center;
        padding-left: 8.33vw;
        align-items: flex-start;
      }
    }
  }

  @media ${device.laptopL} {
    section {
      div.division {
        a.sbd {
          font-size: 0.8rem;
        }
      }
    }
  }

  @media ${device.desktop} {
    padding: 60px 70px;

    section {
      div.division {
        a.sbd {
          font-size: 1.1rem;
        }
      }
    }
  }

  @media ${device.desktopL} {
    section {
      div.division {
        a.sbd {
          font-size: 1.5rem;
        }
      }
    }
  }

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Logo = styled(Link)`
  background-image: url(${MaluLogo});
  background-repeat: no-repeat;
  background-size: auto;
  height: 45px;
  width: 100%;

  &:hover {
    cursor: pointer;
  }

  @media screen and (orientation: landscape) and (min-device-width: 600px) and (max-device-width: 750px) {
    width: 60%;
  }

  @media ${device.tablet} {
    width: 260px;
    margin: 0 auto;
  }

  @media ${device.laptop} {
    background-image: url(${MaluLogoDesktop});
    width: 88%;
  }
`;

export const NavWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  div.little-square {
    width: 5px;
    height: 5px;
    background: #e49b86;
    margin: 0.4rem 0 1.5rem 0;
  }

  a.blog-link {
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem !important;
    letter-spacing: 0.1rem !important;
    font-weight: 400 !important;
    color: #7e7e7e !important;
    position: relative;

    &::before {
      position: absolute;
      top: 0;
      left: -7px;
      content: '';
      width: 30px;
      height: 0;
      opacity: 0.8;
      z-index: -1;
      background: #ffffff;
    }

    &:hover::before {
      animation: 0.3s show2 forwards;
      /* animation-direction: reverse; */
      animation-timing-function: ease-out;
      /* animation-delay: 100ms; */
    }

    @keyframes show2 {
      0% {
        height: 0;
      }
      100% {
        height: 100%;
      }
    }
  }

  @media ${device.tablet} {
    a.blog-link {
      margin-bottom: 0.8rem;
      font-size: 2rem !important;
      letter-spacing: 0.25rem !important;
      font-weight: 200 !important;
      text-align: start;

      &::before {
        width: 38px;
        left: -3px;
      }
    }
  }

  @media ${device.laptop} {
    margin-bottom: 1.8rem;
    align-items: flex-start;

    div.little-square {
      width: 0.6vw;
      height: 0.6vw;
    }
  }

  @media ${device.laptopL} {
    a.blog-link {
      font-size: 2.8rem !important;

      &::before {
        width: 50px;
      }
    }
  }

  @media ${device.desktop} {
    a.blog-link {
      font-size: 3.6rem !important;
      letter-spacing: 0.3rem !important;

      &::before {
        width: 70px;
      }
    }
  }

  @media ${device.desktopL} {
    div.little-square {
      margin: 1.4rem 0 2.8rem 0;
    }

    a.blog-link {
      margin-bottom: 1.4rem;
      font-size: 5rem !important;
      letter-spacing: 0.6rem !important;

      &::before {
        width: 90px;
      }
    }
  }
`;

export const Nav = styled.nav``;

export const Instagram = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #7e7e7e !important;
  position: static;

  &:hover {
    color: #ffffff;
  }

  .instagram {
    font-size: 32px;
  }

  @media ${device.laptop} {
    position: absolute;
    right: 60px;
    bottom: 50px;

    .instagram {
      font-size: 22px;
    }
  }

  @media ${device.desktop} {
    width: 75px;
    right: 70px;
    bottom: 80px;

    .instagram {
      font-size: 32px;
    }
  }
`;
