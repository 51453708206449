import styled from 'styled-components';

import { device } from '~/styles/device';

export const Wrapper = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${device.laptop} {
    align-items: flex-start;
  }
`;

export const Purpose = styled.div`
  display: none;
  position: fixed;

  @media ${device.laptop} {
    width: 8.4vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    a {
      width: 60%;
      margin-bottom: 1vw;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  h1.title-desktop {
    display: none;
    height: 14vh;
  }

  @media ${device.laptop} {
    align-items: flex-start;
    margin-left: 8.4vw;

    h1.title-desktop {
      width: 100%;
      text-align: left;
      display: block;
      font-family: 'Montserrat', sans-serif;
      font-weight: 200;
      display: flex;
      align-items: center;
      font-size: 38px;
      letter-spacing: 4px;
      position: fixed;
      top: 0;
    }
  }

  @media ${device.desktop} {
    h1.title-desktop {
      font-size: 48px;
    }
  }

  @media ${device.desktopL} {
    h1.title-desktop {
      font-size: 70px;
    }
  }
`;

export const Content = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${device.laptop} {
    width: calc(100% - 50px);
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 14vh;
  }
`;

export const Cover = styled.div`
  width: 100%;
  height: 40vh;
  margin: 40px 0 0 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media ${device.laptop} {
    margin: 0;
    height: 86vh;
    width: 33vw;
    position: fixed;
    padding-bottom: 5px;
  }
`;

export const BackDesktop = styled.div`
  width: 100%;
  height: 22px;
  margin: 0 0 30px;
  padding: 0 20px 0 10px;
  display: none;
  justify-content: space-between;

  a.back-arrow {
    span.back-line {
      background: #eabd9c;
      width: 1.3vw;
      height: 0.3vw;
      display: block;
      transform-origin: center left;
      transform: translateY(0vw);
    }

    span.top {
      margin-bottom: -0.3vw;
      transform: rotate(-45deg) translateY(1.1vw);
    }

    span.bottom {
      transform: rotate(45deg) translateY(-0.1vw) translateX(1vw);
    }
  }

  p {
    font-size: 11.3px;
    color: #c3c3c3;
    margin-top: 5px;
  }

  @media ${device.laptop} {
    display: flex;
  }

  @media ${device.desktop} {
    height: 32px;

    p {
      font-size: 16.3px;
    }
  }

  @media ${device.desktopL} {
    height: 44px;

    p {
      font-size: 22.3px;
    }
  }
`;

export const Text = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  flex-direction: column;

  h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
    color: #444444;
    font-size: 46px;
    line-height: 46px;
    margin: 24px 0 20px 0;
  }

  div.social-mobile {
    display: flex;
    justify-content: space-evenly;
    width: 40%;

    a {
      img {
        width: 34px;
      }
    }
  }

  .megadraft-editor {
    padding: 20px;
  }

  .megadraft-editor .DraftEditor-root,
  .megadraft-editor .toolbar {
    font-family: 'Merriweather', serif;
    color: #999999;
    font-size: 0.8rem;
    line-height: 1.1rem;
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .megadraft-editor .paragraph + .paragraph {
    margin-top: 0px;
  }

  .megadraft-editor .public-DraftEditor-content figure {
    margin: 0px 0;
  }

  .megadraft-editor .public-DraftStyleDefault-ol,
  .megadraft-editor .public-DraftStyleDefault-ul {
    line-height: 1rem;
    margin: 0px 0 0px 0;
    padding-left: 40px;
    align-items: flex-start;
  }

  .megadraft-editor blockquote {
    border-left: 2px solid #ddd;
    color: #999;
    font-style: italic;
    margin: 6px 0 6px 0;
    padding: 6px 0 5px 18px;
  }

  .megadraft-editor .paragraph + blockquote {
    margin-top: 0px;
  }

  .megadraft-editor h2 {
    font-family: 'Montserrat', Sans-serif;
    font-weight: 200;
    font-size: 1.7rem;
    letter-spacing: -0.056rem;
    line-height: 2rem;
    margin: 10px 0 10px 0;
    color: #777777;
  }

  .megadraft-editor h2:first-child {
    margin-top: 0;
  }

  .megadraft-editor .sidemenu__button {
    cursor: default;
  }

  .megadraft-editor .sidemenu__button--open {
    transform: rotate(0deg);
  }

  .megadraft-editor .sidemenu__button--open:before {
    background-color: #181818;
  }

  @media ${device.laptop} {
    width: 58vw;
    margin-left: 33vw;
    margin-bottom: 30px;
    align-items: flex-start;
    padding: 0 3vw;

    h1 {
      margin: 0 20px 0px;
      font-size: 42px;
      line-height: 42px;
    }

    div.social-mobile {
      display: none;
    }
  }

  @media ${device.desktop} {
    h1 {
      margin-bottom: 0px;
      font-size: 90px;
      line-height: 90px;
    }

    .megadraft-editor .DraftEditor-root,
    .megadraft-editor .toolbar {
      font-size: 1.1rem;
      line-height: 1.4rem;
    }

    .megadraft-editor h2 {
      font-family: 'Montserrat', Sans-serif;
      font-weight: 200;
      font-size: 3rem;
      letter-spacing: -0.056rem;
      line-height: 2rem;
      margin: 20px 0 20px 0;
      color: #777777;
    }
  }

  @media ${device.desktopL} {
    .megadraft-editor .DraftEditor-root,
    .megadraft-editor .toolbar {
      font-size: 1.4rem;
      line-height: 1.9rem;
    }

    .megadraft-editor h2 {
      font-family: 'Montserrat', Sans-serif;
      font-weight: 200;
      font-size: 3.4rem;
      letter-spacing: -0.056rem;
      line-height: 2.2rem;
      margin: 20px 0 20px 0;
      color: #777777;
    }
  }
`;

export const SocialDesktop = styled.div`
  display: none;
  width: 100%;
  /* margin: 0 40px; */

  hr {
    height: 1px;
    border: 0;
    margin: 10px 15px 12px 20px;
    background: #d9d9d9;
  }

  div.social-desktop {
    display: flex;
    justify-content: space-evenly;
    width: 14%;

    a {
      img {
        width: 34px;
      }
    }
  }

  @media ${device.laptop} {
    display: flex;
    flex-direction: column;
  }

  @media ${device.desktopL} {
    hr {
      margin: 20px 15px 22px 20px;
    }

    div.social-desktop {
      width: 10%;

      a {
        img {
          width: 50px;
        }
      }
    }
  }
`;
