import styled from 'styled-components';

import { Link } from 'react-router-dom';

import { device } from '../../styles/device';

export const MenuItem = styled(Link)`
  margin-bottom: 1.3rem;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none !important;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem !important;
  letter-spacing: 0.1rem !important;
  color: #7e7e7e !important;
  font-weight: 400;
  position: relative;
  text-align: center;

  &::before {
    position: absolute;
    top: 0;
    left: -7px;
    content: '';
    width: 30px;
    height: 0;
    opacity: 0.8;
    z-index: -1;
    background: #ffffff;
  }

  &:hover::before {
    animation: 0.3s show forwards;
    /* animation-direction: reverse; */
    animation-timing-function: ease-out;
    /* animation-delay: 100ms; */
  }

  @keyframes show {
    0% {
      height: 0;
    }
    100% {
      height: 100%;
    }
  }

  @media ${device.tablet} {
    margin-bottom: 0.8rem;
    font-size: 2rem !important;
    letter-spacing: 0.25rem !important;
    font-weight: 200;
    text-align: start;

    &::before {
      width: 38px;
      left: -3px;
    }
  }

  @media ${device.laptopL} {
    font-size: 2.8rem !important;

    &::before {
      width: 50px;
    }
  }

  @media ${device.desktop} {
    font-size: 3.6rem !important;
    letter-spacing: 0.3rem !important;

    &::before {
      width: 70px;
    }
  }

  @media ${device.desktopL} {
    margin-bottom: 1.4rem;
    font-size: 5rem !important;
    letter-spacing: 0.6rem !important;

    &::before {
      width: 90px;
    }
  }
`;
