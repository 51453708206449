import React from 'react';

import { Container } from './styles';

import Purpose from '../../assets/logos/purpose.svg';

export default function Footer() {
  return (
    <Container>
      <p>
        Copyright &copy; Dra. Maria Luiza Dabronzo - {new Date().getFullYear()}/
        {new Date().getFullYear() + 1}.
      </p>
      <p>
        Todos os direitos reservados - Desenvolvido por{' '}
        <a
          href="http://www.wearepurpose.com.br"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Purpose} alt="Purpose Logo" />
        </a>
      </p>
    </Container>
  );
}
