import React from 'react';

import Sidebar from '../../components/Sidebar';

import { Container } from './styles';

export default function FourOFour() {
  return (
    <Container>
      <h1>404</h1>
      <Sidebar />
    </Container>
  );
}
