import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { MenuItem } from './styles';

class MenuItems extends Component {
  static propTypes = {
    menu: PropTypes.shape({
      menuOpen: PropTypes.bool,
      section: PropTypes.string.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired,
  };

  state = {
    hover: false,
  };

  handleHover = () => {
    const { hover } = this.state;

    this.setState({
      hover: !hover,
    });
  };

  handleMenuItemClick = () => {
    const { menu, dispatch } = this.props;

    dispatch({
      type: 'TOGGLE_MENU',
      menuOpen: menu.menuOpen,
    });
  };

  scrollMain = () => {
    const { url, dispatch } = this.props;

    dispatch({
      type: 'SCROLL_MAIN_PAGE',
      section: url,
    });
  };

  render() {
    const { children } = this.props;
    return (
      <MenuItem
        to="/"
        onClick={() => {
          this.handleMenuItemClick();
          this.scrollMain();
        }}
      >
        {children}
      </MenuItem>
    );
  }
}

const mapStateToProps = state => ({
  menu: state.menu,
});

export default connect(mapStateToProps)(MenuItems);
