import styled from 'styled-components';

import { device } from '../../styles/device';

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 50px !important;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 3;

  @media screen and (orientation: landscape) and (min-device-width: 1000px) and (max-device-width: 1200px) {
    width: 50px !important;
  }

  @media ${device.laptop} {
    height: 100%;
    width: 3.91vw;
    left: calc(100vw - 50px - ${props => props.distance}px);
    /* left: ${props =>
      props.post !== ''
        ? 'calc(100vw - 50px)'
        : `calc(100vw - 50px - ${props.distance}px)`}; */
  }

  @media ${device.desktop} {
    width: 75px !important;
    left: calc(100vw - 75px - ${props => props.distance}px);
  }
`;

export const LogoWrapper = styled.div`
  display: none;

  img {
    display: none;
  }

  @media ${device.laptop} {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    width: 50px;
    height: 70%;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  @media ${device.desktop} {
    width: 75px;
    padding: 20px;
  }
`;

export const Instagram = styled.div`
  display: none;

  @media ${device.laptop} {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #e49b86;
    color: #ffffff;

    &:hover {
      background: #eabd9c;
    }

    .instagram {
      font-size: 22px;
    }
  }

  @media ${device.desktop} {
    height: 75px;

    .instagram {
      font-size: 32px;
    }
  }
`;
