import styled from 'styled-components';

import { device } from '../../styles/device';

export const Wrapper = styled.div`
  width: 50px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 99;

  @media ${device.desktop} {
    height: 60px;
    width: 70px;
  }
`;

export const TopArrow = styled.span`
  width: 18px;
  height: 2px;
  background: #000000;
  margin-bottom: 4px;
  transition: all 0.2s ease;

  transform: ${props =>
    props.menuOpen ? 'translateY(3px) rotate(45deg)' : 'none'};
  transform-origin: 'top left';

  @media ${device.desktop} {
    width: 25px;
    height: 3px;
    /* margin-bottom: 5px; */
    margin-bottom: ${props => (props.menuOpen ? '3px' : '5px')};
  }
`;

export const BottomArrow = styled.span`
  width: 18px;
  height: 2px;
  background: #000000;
  transition: all 0.2s ease;

  transform: ${props =>
    props.menuOpen ? 'translateY(-3px) rotate(-45deg)' : 'none'};
  transform-origin: 'top left';

  @media ${device.desktop} {
    width: 25px;
    height: 3px;
  }
`;
