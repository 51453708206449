import styled from 'styled-components';
import { device } from '../../styles/device';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  background: #fff;
  padding: 30px 10%;
  flex-direction: column;

  p {
    color: #b3b3b3;
    font-size: 10px;
    display: flex;
    height: 16px;
    margin-right: 5px;

    a {
      height: 16px;
      display: flex;

      img {
        height: 16px;
        padding-bottom: 1px;
      }
    }
  }

  @media ${device.laptop} {
    flex-direction: row;
    padding: 2vw 10%;
  }

  @media ${device.laptopL} {
    p {
      font-size: 12px;

      a {
        height: 18px;

        img {
          height: 18px;
        }
      }
    }
  }

  @media ${device.desktop} {
    p {
      font-size: 14px;

      a {
        height: 20px;

        img {
          height: 22px;
        }
      }
    }
  }

  @media ${device.desktopL} {
    p {
      font-size: 16px;

      a {
        height: 24px;

        img {
          height: 24px;
        }
      }
    }
  }
`;
