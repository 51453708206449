import produce from 'immer';

export default function menu(state = { menuOpen: false, section: '' }, action) {
  switch (action.type) {
    case 'TOGGLE_MENU':
      return produce(state, draft => {
        if (draft.menuOpen === action.menuOpen) {
          draft.menuOpen = !action.menuOpen;
          draft.section = '';
        }
      });
    case 'SCROLL_MAIN_PAGE':
      return produce(state, draft => {
        draft.section = action.section;
      });
    default:
      return state;
  }
}
