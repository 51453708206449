import React, { Component } from 'react';
import { Form, Input } from '@rocketseat/unform';
import MaskedInput from 'react-text-mask';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { css } from 'glamor';
import axios from 'axios';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Grid,
  Container,
  Header,
  About,
  How,
  Procedures,
  Know,
  Where,
} from './styles';

import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';

import Arrow from '../../assets/images/arrow.svg';
import LogoOneLineBlack from '../../assets/logos/logo-one-line-black.svg';
import LogoTwoLineBlack from '../../assets/logos/logo-two-lines-black.svg';

import DermatoClinica from '../../assets/images/dermato_clinica.svg';
import DermatoCirurgica from '../../assets/images/cirurgica.svg';
import Estetica from '../../assets/images/estetica.svg';
import Tricologia from '../../assets/images/tricologia.svg';

import HomePicture from '../../assets/pictures/home.png';
import MobileCRM from '../../assets/images/CRM-150.813.png';
import MaluPicture from '../../assets/pictures/malu.png';
import Women from '../../assets/pictures/women.png';
import How1 from '../../assets/pictures/how_1.png';
import How2 from '../../assets/pictures/how_2.png';
import Procedures1 from '../../assets/pictures/procedures_1.png';
import Procedures2 from '../../assets/pictures/procedures_2.png';

import api from '~/services/api';

const schema = Yup.object().shape({
  name: Yup.string().required('Por favor, informe o seu nome.'),
  email: Yup.string()
    .email('Por favor, insira um e-mail válido.')
    .required('Por favor, informe o seu e-mail.'),
});

class Main extends Component {
  static propTypes = {
    menu: PropTypes.shape({
      menuOpen: PropTypes.bool,
      section: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.top = React.createRef();
    this.about = React.createRef();
    this.how = React.createRef();
    this.procedures = React.createRef();
    this.know = React.createRef();
    this.where = React.createRef();

    this.state = {
      selectedOption: 'SA',
    };
  }

  componentDidUpdate() {
    const { menu } = this.props;

    switch (menu.section) {
      case 'top':
        this.handleTopScroll();
        break;

      case 'sou':
        this.handleAboutScroll();
        break;

      case 'como':
        this.handleHowScroll();
        break;

      case 'procedimentos':
        this.handleProceduresScroll();
        break;

      case 'conheca':
        this.handleKnowScroll();
        break;

      case 'onde':
        this.handleWhereScroll();
        break;

      default:
        break;
    }
  }

  handleTopScroll = () => {
    const topRef = this.top.current;

    topRef.scrollIntoView({ behavior: 'smooth' });
  };

  handleAboutButtonScroll = e => {
    e.preventDefault();
    const aboutRef = this.about.current;

    aboutRef.scrollIntoView({ behavior: 'smooth' });
  };

  handleAboutScroll = () => {
    const aboutRef = this.about.current;

    aboutRef.scrollIntoView({ behavior: 'smooth' });
  };

  handleHowScroll = () => {
    const howRef = this.how.current;

    howRef.scrollIntoView({ behavior: 'smooth' });
  };

  handleProceduresScroll = () => {
    const proceduresRef = this.procedures.current;

    proceduresRef.scrollIntoView({ behavior: 'smooth' });
  };

  handleKnowScroll = () => {
    const knowRef = this.know.current;

    knowRef.scrollIntoView({ behavior: 'smooth' });
  };

  handleWhereScroll = () => {
    const whereRef = this.where.current;

    whereRef.scrollIntoView({ behavior: 'smooth' });
  };

  handleOptionChange = e => {
    this.setState({
      selectedOption: e.target.value,
    });
  };

  handleSendEmail = async (data, { resetForm }) => {
    try {
      const phoneInput = document.querySelector('input[name=phone]');
      const { selectedOption } = this.state;

      data.phone = phoneInput.value;
      data.place = selectedOption;

      await api.post('leads', {
        name: data.name,
        email: data.email,
        phone: data.phone,
        place: data.place,
      });

      resetForm();
      phoneInput.value = '';

      toast('Dados enviados com sucesso!', {
        className: css({
          background: '#BF846A !important',
        }),
        bodyClassName: css({
          fontSize: '14px',
          fontStyle: 'italic !important',
          fontFamily: 'Merriweather, serif !important',
          fontWeight: '200 !important',
          color: '#FFFFFF !important',
        }),
        progressClassName: css({
          background: 'rgba(255,255,255,0.3) !important',
        }),
      });
    } catch (err) {
      console.tron.log(err);
      toast.error('Ocorreu um erro no envio. Tente novamente.');
    }
  };

  render() {
    const { selectedOption } = this.state;

    return (
      <>
        <Grid ref={this.top}>
          <Header>
            <div className="square" id="square-a" />
            <div className="square" id="square-b" />
            <div className="square" id="square-c" />
            <div className="square" id="square-d" />
            <div className="square" id="square-e" />

            <div className="wrapper">
              <div className="texts-wrapper">
                <div className="logo-wrapper">
                  <img
                    src={LogoOneLineBlack}
                    alt="Logo Malu Dabronzo"
                    className="logo-desktop"
                  />
                  <img
                    src={LogoTwoLineBlack}
                    alt="Logo Malu Dabronzo"
                    className="logo-mobile"
                  />
                </div>

                <h3>CRM 150.813</h3>
                <img src={MobileCRM} alt="CRM" className="crm" />
              </div>
            </div>

            <img
              className="home-picture"
              src={HomePicture}
              alt="Person with blue eyes looking ahead"
            />

            <button
              type="button"
              className="arrow-wrapper"
              onClick={this.handleAboutButtonScroll}
            >
              <img src={Arrow} alt="Down arrow" />
            </button>

            <Sidebar />
          </Header>
        </Grid>
        <Container>
          <About ref={this.about}>
            <p className="slogan">É sobre pele e saúde. É sobre você.</p>

            <div className="about-wrapper">
              <div className="about-text">
                <h2>Sou</h2>
                <div className="about-paragraph">
                  <p>
                    Sou apaixonada por como a dermatologia pode proporcionar
                    melhora da qualidade de vida e da saúde das pessoas através
                    do cuidado, não só da pele, mas também dos cabelos e das
                    unhas.
                  </p>
                  <h4>Maria Luíza Dabronzo. Malu.</h4>
                  <div className="little-square" />

                  <div className="mobile-wrapper" />

                  <p>
                    A dermatologia é grande parte da minha vida, me graduei em
                    medicina pela{' '}
                    <span className="highlight">Universidade de São Paulo</span>{' '}
                    e fiz residência em dermatologia no{' '}
                    <span className="highlight">
                      Hospital das Clínicas da Faculdade de Medicina da USP
                    </span>
                    . Além da minha base em dermatologia, complementei meus
                    estudos em Cosmiatria e LASER também no Hospital das
                    Clínicas da Faculdade de Medicina da USP e sou membro
                    Titular da Sociedade Brasileira de Dermatologia.
                  </p>
                  <p>
                    Quando me procuram seja com queixas de doenças ou de
                    estética, me preocupo em examinar todo corpo de meus
                    pacientes e estar atenta a suas queixas discutindo as
                    melhores possibilidades para o cuidado ou tratamento.
                  </p>
                </div>
              </div>

              <img
                src={MaluPicture}
                alt="foto da Maria Luiza Dabronzo"
                className="malu-desktop"
              />

              <div className="square" id="square-f" />
            </div>

            <div className="slogan-wrapper">
              <div className="dermato-images">
                <div className="square" id="square-g" />
                <img src={Women} alt="" />
                <div className="text">
                  <h3>Dermatologia</h3>
                </div>
              </div>

              <h3>Bem estar através do cuidado com a saúde e a beleza.</h3>
              <div className="square" id="square-f" />
            </div>
          </About>
          <How ref={this.how}>
            <h2>Como?</h2>
            <div className="how-wrapper">
              <div className="text">
                <p>De crianças a adultos.</p>
                <p>
                  Ter contato com diversos tipos de histórias, jeitos e desejos
                  me impulsiona cada vez mais a trazer tratamentos que ajudem
                  vidas a se tornarem melhores.
                </p>
                <div className="little-square" />
                <p>
                  Atuo em consultório trazendo um cuidado exclusivo para meus
                  pacientes através das diferentes áreas da dermatologia
                  clínica, cirúrgica e estética. Além de cuidar de doenças que
                  acometem os cabelos com a tricologia.
                </p>
                <div className="little-square" />
                <p>
                  Trabalho também como Clinical Specialist da Allergan, empresa
                  responsável pela fabricação e comercialização de{' '}
                  <span>
                    toxina botulínica e preenchedores de ácido hialurônico
                  </span>
                  , atuando na área médica, a{' '}
                  <span>Allergan Medical Institute®</span>, ministrando
                  treinamentos para médicos sobre a utilização desses produtos.
                </p>
              </div>
              <div className="images">
                <img
                  className="first-pic"
                  src={How1}
                  alt="Malu analisando braço"
                />
                <img
                  className="second-pic"
                  src={How2}
                  alt="Braço estendido contra a luz"
                />
              </div>
            </div>
          </How>
          <Procedures ref={this.procedures}>
            <h2>Procedimentos</h2>
            <div className="proc-wrapper">
              <div id="img-1" className="img-desktop">
                <img src={Procedures1} alt="Malu folheando livro" />
              </div>

              <div id="thumb1" className="thumbs-wrapper">
                <div className="thumb">
                  <div className="icon">
                    <img
                      src={DermatoClinica}
                      alt="Ícone de dermatologia clínica"
                    />
                  </div>
                  <h6>Dermatologia clínica</h6>
                  <ul>
                    <li>Exame dermatológico completo</li>
                    <li>Dermatoscopia</li>
                    <li>Check-up dermatológico</li>
                  </ul>
                </div>

                <div className="thumb">
                  <div className="icon">
                    <img
                      src={DermatoCirurgica}
                      alt="Ícone de dermatologia cirúrgica"
                    />
                  </div>
                  <h6>Dermatologia cirúrgica</h6>
                  <ul>
                    <li>Biópsia</li>
                    <li>Eletrocirurgia</li>
                    <li>Criocirurgia</li>
                    <li>Pequenas cirurgias</li>
                    <li>Cirugia de unha</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="proc-wrapper">
              <div id="thumb2" className="thumbs-wrapper">
                <div className="thumb">
                  <div className="icon">
                    <img src={Estetica} alt="Ícone de estética" />
                  </div>
                  <h6>Estética</h6>
                  <ul>
                    <li>Toxina botulínica</li>
                    <li>Preenchedores de ácido hialurônico</li>
                    <li>Peeling químico</li>
                    <li>Microagulhamento</li>
                  </ul>
                </div>

                <div className="thumb">
                  <div className="icon">
                    <img src={Tricologia} alt="Ícone de tricologia" />
                  </div>
                  <h6>Tricologia</h6>
                  <ul>
                    <li>Tricoscopia</li>
                    <li>Microagulhamento</li>
                  </ul>
                </div>
              </div>

              <div id="img-2" className="img-desktop">
                <div id="square-g" className="square" />
                <img
                  src={Procedures2}
                  alt="Malu vestindo luvas e tratando rosto"
                />
              </div>
            </div>
          </Procedures>
          <Know ref={this.know}>
            <h2>Conheça</h2>
            <div id="square-h" className="square" />

            <div className="contact-wrapper">
              <div className="text">
                <h6>Agende sua consulta</h6>
                <p>
                  Preencha seus dados, entraremos em contato o mais breve
                  possível.
                </p>
              </div>

              <Form schema={schema} onSubmit={this.handleSendEmail}>
                <Input name="name" type="text" placeholder="Nome" />
                <Input name="email" type="e-mail" placeholder="E-mail" />
                <MaskedInput
                  name="phone"
                  type="text"
                  mask={[
                    '(',
                    /[1-9]/,
                    /\d/,
                    ')',
                    ' ',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  placeholder="Celular"
                />

                <div className="radio-buttons">
                  <label>
                    <Input
                      name="place"
                      type="radio"
                      value="SA"
                      checked={selectedOption === 'SA'}
                      onChange={this.handleOptionChange}
                      className="form-check-input"
                    />
                    <span className="checkmark" />
                    <span className="radio-title">Santo André</span>
                  </label>

                  <label>
                    <Input
                      name="place"
                      type="radio"
                      value="SP"
                      checked={selectedOption === 'SP'}
                      onChange={this.handleOptionChange}
                      className="form-check-input"
                    />
                    <span className="checkmark" />
                    <span className="radio-title">São Paulo</span>
                  </label>
                </div>

                <button type="submit">Enviar</button>

                <div className="data-warning">
                  <p>*Seus dados jamais serão divulgados ou compartilhados!</p>
                </div>
              </Form>
            </div>

            <div id="square-i" className="square" />
          </Know>
          <Where ref={this.where}>
            <h2>Onde?</h2>
            <div className="where-wrapper">
              <div className="text">
                <div id="first-location" className="location">
                  <h3>Consultório Santo André | SP</h3>

                  <a
                    href="https://goo.gl/maps/gdukHdsaEtPr6pq56"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ul className="adress">
                      <li>Rua das Goiabeiras, 296</li>
                      <li>Bairro Jardim</li>
                      <li>Santo André | SP</li>
                    </ul>
                  </a>

                  <ul className="phones">
                    <li>11 4438.5370 | 11 4438.6397</li>
                    <li>
                      <i className="fab fa-whatsapp" /> 11 9.9966.4496
                    </li>
                  </ul>
                </div>

                <div id="second-location" className="location">
                  <h3>Consultório Vila Mariana | SP</h3>

                  <a
                    href="https://goo.gl/maps/MijUi5n4kMB61AVcA"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ul className="adress">
                      <li>Rua Cubatão, 929, sala 93</li>
                      <li>Vila Mariana</li>
                      <li>São Paulo | SP</li>
                    </ul>
                  </a>

                  <ul className="phones">
                    <li>11 2645.5020</li>
                    <li>
                      <i className="fab fa-whatsapp" /> 11 9.7365.7829
                    </li>
                  </ul>
                </div>

                <div className="location">
                  <h3>Consultório Pinheiros | SP</h3>

                  <a
                    href="https://goo.gl/maps/xUqW6bAjVzkko8dq6"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ul className="adress">
                      <li>Rua João Moura, 661, conjunto 85</li>
                      <li>Pinheiros</li>
                      <li>São Paulo | SP</li>
                    </ul>
                  </a>

                  <ul className="phones">
                    <li>11 4279.9229</li>
                    <li>
                      <i className="fab fa-whatsapp" /> 11 4172.0517
                    </li>
                  </ul>
                </div>
              </div>

              <a
                className="desktop-contact"
                href="mailto:contato@maludabronzo.com.br"
              >
                contato<span>@</span>maludabronzo.com.br
              </a>
            </div>

            <div className="mobile">
              <a
                className="mobile-contact"
                href="mailto:contato@maludabronzo.com.br"
              >
                contato<span>@</span>maludabronzo.com.br
              </a>
            </div>

            <div className="where-img" />
          </Where>
        </Container>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = state => ({
  menu: state.menu,
});

export default connect(mapStateToProps)(Main);
