import styled from 'styled-components';
import { device } from '../../styles/device';

import MaluPicture from '../../assets/pictures/malu.png';
import Feet from '../../assets/pictures/feet.png';

export const Grid = styled.div`
  display: grid;
  grid-template-rows: [header] 100vh [content] auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas:
    'header header header header'
    'content content content content';

  @media ${device.laptop} {
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas:
      'header header header header header header header header header header header header'
      'content content content content content content content content content content content content';
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  /* @media ${device.laptop} {
    width: 96.09vw;
  } */
`;

export const Header = styled.header`
  grid-area: header;
  grid-column-end: -1;
  display: flex;
  justify-content: space-between;
  position: relative;

  #square-a {
    display: none;
  }

  #square-b {
    top: 15vh;
    left: 0;
    background: #e49b86;
    min-width: 90px;
    width: 14vw;
    height: 40vh;
  }

  #square-c {
    top: 0;
    left: 65vw;
    background: #f4e5de;
    width: 20vw;
    height: 20vh;
  }

  #square-d {
    left: 60vw;
    bottom: 10vh;
    width: 40vw;
    height: 12vh;
    min-height: 80px;
    background: #f8e2d6;
  }

  #square-e {
    background: #eabd9c;
    left: 0;
    bottom: -18vh;
    height: 27vh;
    width: 12vw;
  }

  .wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    padding: 0 5%;
  }

  .texts-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
      display: none;
    }

    img.crm {
      display: block;
      position: absolute;
      left: 0;
      bottom: 16vh;
      width: 65px;
    }
  }

  .logo-wrapper {
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: center;

    img.logo-mobile {
      display: block;
      height: 100%;
      width: 100%;
    }

    img.logo-desktop {
      display: none;
      height: 100%;
      width: 100%;
    }
  }

  img.home-picture {
    display: none;
  }

  button.arrow-wrapper {
    background: #ffffff;
    border: none;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -30%);
    transition: all 0.2s ease-in-out;

    &:hover {
      cursor: pointer;
      width: 43px;
      height: 43px;
      transform: translate(-50%, -15%);
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  @media ${device.tablet} {
    #square-c {
      width: 18vw;
      left: 70vw;
    }

    #square-e {
      width: 8vw;
    }

    .logo-wrapper {
      height: 50px;
    }
  }

  @media ${device.laptop} {
    #square-a {
      display: block;
      background: #ad7b57;
      width: 11.04vw;
      height: 9.27vw;
      left: 9vw;
      top: 15vh;
    }

    #square-b {
      top: 0;
      left: 41.5vw;
      width: 7.55vw;
      height: 15vw;
    }

    #square-c {
      top: 20vh;
      left: 62vw;
      width: 30vw;
      height: 70vh;
    }

    #square-d {
      display: none;
    }

    #square-e {
      width: 5vw;
      bottom: -40vh;
      height: 80vh;
    }

    .wrapper {
      padding: 0 0 0 9vw;
    }

    .texts-wrapper {
      justify-content: flex-start;

      h3 {
        display: block;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 200;
        transform-origin: 0% 50%;
        transform: rotate(270deg);
        position: absolute;
        bottom: 0;
        margin-bottom: 2vh;
        color: #7e7e7e;
        justify-content: flex-start;
      }

      img.crm {
        display: none;
      }
    }

    .logo-wrapper {
      width: 35.9vw;
      display: flex;
      justify-content: flex-start;

      img.logo-mobile {
        display: none;
      }

      img.logo-desktop {
        display: block;
      }
    }

    img.home-picture {
      display: block;
      position: absolute;
      height: 100vh;
      right: 0;
    }
  }

  @media ${device.desktop} {
    #square-e {
      bottom: -32vh;
      height: 68vh;
    }

    .texts-wrapper {
      h3 {
        font-size: 18px;
      }
    }

    button.arrow-wrapper {
      width: 50px;
      height: 50px;

      &:hover {
        cursor: pointer;
        width: 58px;
        height: 58px;
        transform: translate(-50%, -15%);
      }
    }
  }
`;

export const About = styled.section`
  background: #ffffff;
  width: 100%;

  p.slogan {
    margin: 0 auto;
    text-align: center;
    margin: 70px 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
  }

  div.about-wrapper {
    display: flex;
    justify-content: center;
    margin: 10vh 5% 0 5%;
    position: relative;

    #square-f {
      width: 62vw;
      height: 22vw;
      background: #f6ebde;
      left: 13.5vw;
      top: 33px;
    }

    div.about-text {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      flex-direction: column;

      h2 {
        font-family: 'Montserrat', sans-serif;
        text-transform: uppercase;
        color: #bdbdbd;
        letter-spacing: 8px;
        font-weight: 200;
        font-size: 72px;
        line-height: 72px;
        width: 80%;
        z-index: 1;
        margin-bottom: 80px;
        text-align: right;
      }

      div.about-paragraph {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        p,
        h4 {
          line-height: 20px;
          margin-bottom: 18px;
          font-weight: normal;
          width: 80%;

          span {
            color: #e49b86;
          }
        }

        h4 {
          margin-bottom: 25px !important;
          line-height: 12px !important;
        }

        div.little-square {
          width: 10px;
          height: 10px;
          background: #e49b86;
          margin-right: 69vw;
        }

        div.mobile-wrapper {
          display: block;
          width: 100%;
          height: 180px;
          background-image: url(${MaluPicture});
          background-size: cover;
          background-repeat: no-repeat;
          background-position: 50% 20%;
          margin: 25px 0 50px 0;
        }
      }
    }

    img {
      width: 29vw;
      height: 46.44vw;
      margin-left: 3.125vw;
      transform: translateY(-16%);
      display: none;
    }
  }

  div.slogan-wrapper {
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    position: relative;
    height: 100vh;
    margin: 50px 4vw 0 4vw;

    div.dermato-images {
      display: flex;
      justify-content: space-between;
      position: relative;
      width: 90vw;
      height: 104.7vw;

      #square-g {
        width: 74.71vw;
        height: 42.13vw;
        background: #d3bcb1;
        position: absolute;
        top: -10vw;
        right: 0;
      }

      img {
        width: 80vw;
        height: 95.35vw;
        z-index: 1;
      }

      div.text {
        width: 10vw;
        height: 104.7vw;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0 6%;

        h3 {
          transform: rotate(270deg);
          transform-origin: 0 0;
          letter-spacing: 10px;
          font-weight: 500;
          color: #b7b7b7;
          font-size: 14px;
          line-height: 10px;
        }
      }
    }

    h3 {
      font-weight: 200;
      width: 212px;
      font-size: 26px;
      z-index: 1;
      margin: 0 10%;
    }

    #square-f {
      width: 70vw;
      height: 30vw;
      background: #f8e2d6;
      position: absolute;
      right: 0;
      bottom: 10vw;
      margin-right: 5vw;
    }
  }

  @media screen and (orientation: landscape) and (min-device-width: 600px) and (max-device-width: 750px) {
    div.about-wrapper {
      #square-f {
        width: 62vw;
        height: 12vw;
        left: 13.5vw;
        top: 33px;
      }

      div.about-text {
        div.about-paragraph {
          div.mobile-wrapper {
            height: 220px;
            background-position: 50% 25%;
          }
        }
      }
    }

    div.slogan-wrapper {
      height: 100vh;
      margin: 90px 4vw 50vw 4vw;
      align-items: center;

      div.dermato-images {
        width: 60vw;
        height: 69.8vw;

        #square-g {
          width: 49.81vw;
          height: 28.09vw;
          top: -10vw;
          right: 0;
        }

        img {
          width: 50vw;
          height: 59.59vw;
        }

        div.text {
          width: 10vw;
          height: 69.8vw;
          padding: 0 6%;

          h3 {
            letter-spacing: 14px;
            font-size: 16px;
            line-height: 17px;
          }
        }
      }

      h3 {
        font-weight: 200;
        width: 212px;
        font-size: 26px;
      }

      #square-f {
        width: 50vw !important;
        height: 20vw !important;
        background: #f8e2d6 !important;
        position: absolute !important;
        right: 0 !important;
        bottom: -50vw !important;
        margin-right: 5vw !important;
      }
    }
  }

  @media screen and (orientation: landscape) and (min-device-width: 1000px) and (max-device-width: 1200px) {
    div.about-wrapper {
      margin: 23vh 0 0 16.7vw !important;

      div.about-text {
        width: 51vw;

        h2 {
          font-size: 72px !important;
          line-height: 53px !important;
          width: 40% !important;
        }

        div.about-paragraph {
          padding-right: 0px !important;
          width: 56% !important;

          p,
          h4 {
            font-size: 12px;
            line-height: 17px;
            margin-bottom: 22px;
          }

          h4 {
            margin-bottom: 5px !important;
            line-height: 16px !important;
          }

          div.little-square {
            margin-bottom: 22px;
          }
        }
      }
    }

    div.slogan-wrapper {
      height: 100vh;
      margin: 90px 4vw 0 4vw !important;
      align-items: center !important;

      div.dermato-images {
        width: 60vw !important;
        height: 69.8vw !important;

        #square-g {
          width: 49.81vw !important;
          height: 28.09vw !important;
          top: -10vw !important;
          right: 0 !important;
        }

        img {
          width: 50vw !important;
          height: 59.59vw !important;
        }

        div.text {
          width: 10vw !important;
          height: 69.8vw !important;
          padding: 0 6% !important;

          h3 {
            letter-spacing: 22px !important;
            font-size: 24px !important;
            line-height: 33px !important;
          }
        }
      }

      h3 {
        font-weight: 200;
        width: 130px;
        font-size: 17px;
      }

      #square-f {
        width: 29vw !important;
        height: 32.4vw !important;
        background: #f8e2d6 !important;
        position: absolute !important;
        right: 0 !important;
        bottom: -8vw !important;
        margin-right: -8vw !important;
      }
    }
  }

  @media ${device.tablet} {
    p.slogan {
      font-size: 18px;
      margin: 100px 0;
    }

    div.about-wrapper {
      #square-f {
        width: 70vw;
        height: 11vw;
        left: 10vw;
        top: 3vh;
      }

      div.about-text {
        h2 {
          letter-spacing: 14px;
          font-size: 90px;
          line-height: 72px;
        }
        div.about-paragraph {
          div.little-square {
            margin-right: 71vw;
          }

          div.mobile-wrapper {
            height: 300px;
          }
        }
      }
    }

    div.slogan-wrapper {
      height: 100vh;
      margin: 90px 4vw 0 4vw;
      align-items: center;

      div.dermato-images {
        width: 60vw;
        height: 69.8vw;

        #square-g {
          width: 49.81vw;
          height: 28.09vw;
          top: -10vw;
          right: 0;
        }

        img {
          width: 50vw;
          height: 59.59vw;
        }

        div.text {
          width: 10vw;
          height: 69.8vw;
          padding: 0 6%;

          h3 {
            letter-spacing: 14px;
            font-size: 22px;
            line-height: 20px;
          }
        }
      }

      h3 {
        width: 316px;
        font-size: 40px;
        margin: 50px 0 0 0;
      }

      #square-f {
        width: 60vw;
        height: 25vw;
        background: #f8e2d6;
        position: absolute;
        right: 0;
        bottom: 10vw;
        margin-right: 5vw;
      }
    }
  }

  @media ${device.laptop} {
    margin-bottom: 8vw;

    p.slogan {
      margin: 100px 0;
    }

    div.about-wrapper {
      justify-content: space-between;
      margin: 34vh 0 0 16.7vw;

      #square-f {
        width: 33.54vw;
        height: 18.9vw;
        left: -16.7vw;
        top: 45px;
      }

      div.about-text {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        width: 48vw;

        h2 {
          width: 50%;
          margin: 0;
          text-align: left;
          letter-spacing: 18px;
        }

        div.about-paragraph {
          flex-direction: column;
          width: 50%;
          padding-right: 30px;
          align-items: flex-start;

          p,
          h4 {
            font-size: 12px;
            width: 100%;
          }

          h4 {
            margin-bottom: 5px !important;
            line-height: 20px !important;
          }

          div.little-square {
            width: 0.7vw;
            height: 0.7vw;
            margin-bottom: 23px;
          }

          div.mobile-wrapper {
            display: none;
          }
        }
      }

      img {
        display: block;
      }
    }

    div.slogan-wrapper {
      flex-direction: row;
      margin-top: 0;
      align-items: center;

      div.dermato-images {
        display: flex;
        justify-content: space-between;
        position: relative;
        width: 40.4vw;
        height: 47vw;

        #square-g {
          width: 33.54vw;
          height: 18.91vw;
          background: #d3bcb1;
          position: absolute;
          top: -6vw;
          right: 0;
        }

        img {
          width: 34.4vw;
          height: 41vw;
          z-index: 1;
        }

        div.text {
          width: 6vw;
          height: 47vw;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          padding: 0 6%;

          h3 {
            transform: rotate(270deg);
            transform-origin: 0 0;
            letter-spacing: 18px;
            font-weight: 500;
            color: #b7b7b7;
            font-size: 24px;
            line-height: 20px;
          }
        }
      }

      h3 {
        margin: -5vw auto 0 auto;
        font-weight: 200;
        width: 220px;
        font-size: 28px;
      }

      #square-f {
        width: 29vw;
        height: 32.4vw;
        background: #f8e2d6;
        position: absolute;
        right: 0;
        bottom: -8vw;
        margin-right: -6vw;
      }
    }
  }

  @media ${device.laptopL} {
    div.about-wrapper {
      margin-top: 28vh;

      #square-f {
        top: 80px;
      }

      div.about-text {
        width: 52vw;

        h2 {
          font-size: 110px;
          line-height: 108px;
          width: 45%;
        }

        div.about-paragraph {
          padding-right: 40px;

          p,
          h4 {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 22px;
          }

          h4 {
            margin-bottom: 8px !important;
            line-height: 24px !important;
          }

          div.little-square {
            margin-bottom: 22px;
          }
        }
      }
    }

    div.slogan-wrapper {
      margin: 6vw 4vw;

      div.dermato-images {
        div.text {
          h3 {
            font-size: 30px;
            letter-spacing: 16px;
          }
        }
      }

      h3 {
        width: 280px;
        font-size: 32px;
      }

      #square-f {
        height: 33vw;
      }
    }
  }

  @media ${device.desktop} {
    p.slogan {
      font-size: 30px;
      margin: 150px 0;
    }

    div.about-wrapper {
      margin-top: 26vh;

      #square-f {
        top: 85px;
      }

      div.about-text {
        width: 48vw;

        h2 {
          font-size: 140px;
          width: 50%;
          letter-spacing: 20px;
        }

        div.about-paragraph {
          padding-right: 0px;

          p,
          h4 {
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 26px;
          }

          div.little-square {
            margin-bottom: 30px;
          }
        }
      }
    }

    div.slogan-wrapper {
      margin: 0vw 4vw;

      div.dermato-images {
        div.text {
          h3 {
            font-size: 41px;
            letter-spacing: 20px;
            line-height: 31px;
          }
        }
      }

      h3 {
        width: 350px;
        font-size: 44px;
      }

      #square-f {
        height: 34vw;
      }
    }
  }

  @media ${device.desktopL} {
    div.about-wrapper {
      div.about-text {
        h2 {
          font-size: 160px;
          line-height: 118px;
          letter-spacing: 25px;
          width: 50%;
        }

        div.about-paragraph {
          padding-right: 60px;

          p,
          h4 {
            font-size: 26px;
            line-height: 35px;
            margin-bottom: 30px;
          }

          div.little-square {
            margin-bottom: 35px;
          }
        }
      }
    }

    div.slogan-wrapper {
      div.dermato-images {
        div.text {
          h3 {
            font-size: 50px;
            letter-spacing: 30px;
            line-height: 34px;
          }
        }
      }

      h3 {
        width: 440px;
        font-size: 56px;
      }

      #square-f {
        height: 28.23vw;
      }
    }
  }
`;

export const How = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;

  h2 {
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    color: #bdbdbd;
    letter-spacing: 8px;
    font-weight: 200;
    font-size: 72px;
    margin-bottom: 28px;
    margin-top: 0;
    z-index: 1;
  }

  div.how-wrapper {
    width: 90%;
    display: flex;
    flex-direction: column;

    div.text {
      display: flex;
      flex-direction: column;
      margin: 0 10%;

      p {
        font-size: 13px;
        line-height: 20px;
        margin-bottom: 18px;
        font-weight: normal;

        span {
          color: #e49b86;
        }
      }

      div.little-square {
        width: 10px;
        height: 10px;
        background: #e49b86;
        margin-bottom: 18px;
      }
    }

    div.images {
      width: 100%;
      height: 60vh;
      position: relative;
      margin-bottom: 20px;

      img {
        position: absolute;
        width: 60%;
      }

      img.first-pic {
        top: 0;
        right: 0;
        z-index: 1;
      }

      img.second-pic {
        bottom: 0;
        left: 0;
      }
    }
  }

  @media screen and (orientation: landscape) and (min-device-width: 600px) and (max-device-width: 750px) {
    h2 {
      margin-top: 30px;
    }

    div.how-wrapper {
      align-items: center;
      div.images {
        width: 110vh;
        height: 110vh;
        position: relative;

        img {
          position: absolute;
          width: 55%;
        }

        img.first-pic {
          top: 0;
          right: 0;
          z-index: 1;
        }

        img.second-pic {
          bottom: 0;
          left: 0;
        }
      }
    }
  }

  @media ${device.tablet} {
    h2 {
      letter-spacing: 14px;
      font-size: 90px;
      line-height: 72px;
      margin-bottom: 50px;
      margin-top: 10px;
    }

    div.how-wrapper {
      div.images {
        height: 78vh;
        margin-top: 30px;
      }
    }
  }

  @media ${device.laptop} {
    h2 {
      z-index: 2;
      letter-spacing: 18px;
    }

    div.how-wrapper {
      width: 78vw;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 0;
      margin-bottom: 3vw;

      div.text {
        display: flex;
        flex-direction: column;
        margin: 0 0 0 4.167vw;
        width: 22.15vw;

        p {
          font-size: 12px;
          line-height: 20px;
          margin-bottom: 18px;
          font-weight: normal;

          span {
            color: #e49b86;
          }
        }

        div.little-square {
          width: 0.7vw;
          height: 0.7vw;
          background: #e49b86;
          margin-bottom: 18px;
        }
      }

      div.images {
        width: 46vw;
        height: 44vw;
        position: relative;
        margin-bottom: 20px;

        img {
          position: absolute;
        }

        img.first-pic {
          top: -6vw;
          right: 0;
          z-index: 1;
          width: 58%;
        }

        img.second-pic {
          bottom: 0;
          left: 0;
          width: 48%;
        }
      }
    }
  }

  @media ${device.laptopL} {
    h2 {
      font-size: 110px;
    }

    div.how-wrapper {
      div.text {
        p {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 22px;
        }

        div.little-square {
          margin-bottom: 22px;
        }
      }

      div.images {
        margin-bottom: 30px;
      }
    }
  }

  @media ${device.desktop} {
    h2 {
      font-size: 140px;
      margin: 80px 0 100px 0;
      letter-spacing: 20px;
    }

    div.how-wrapper {
      div.text {
        p {
          font-size: 20px;
          line-height: 30px;
          margin-bottom: 26px;
        }

        div.little-square {
          margin-bottom: 26px;
        }
      }

      div.images {
        margin-bottom: 40px;
      }
    }
  }

  @media ${device.desktopL} {
    h2 {
      font-size: 160px;
      margin: 110px 0 130px 0;
      letter-spacing: 25px;
    }

    div.how-wrapper {
      div.text {
        p {
          font-size: 26px;
          line-height: 35px;
          margin-bottom: 30px;
        }

        div.little-square {
          margin-bottom: 30px;
        }
      }

      div.images {
        margin-bottom: 60px;
      }
    }
  }
`;

export const Procedures = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    color: #bdbdbd;
    letter-spacing: 0px;
    font-weight: 200;
    font-size: 36px;
    margin-bottom: 40px;
    margin-top: 10px;
    z-index: 1;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      bottom: -14px;
      left: -7%;
      width: 90px;
      height: 36px;
      background: rgba(228, 155, 134, 0.4);
      display: inline-block;
      z-index: 0;
    }
  }

  div.proc-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    div.img-desktop {
      display: none;
      position: relative;
    }

    div.thumbs-wrapper {
      div.thumb {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 0 25px 0;

        div.icon {
          width: 68px;
          height: 68px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            height: 100%;
            color: #e49b86;
          }
        }

        h6 {
          font-family: 'Montserrat', sans-serif;
          text-transform: uppercase;
          font-size: 20px;
          font-weight: 400;
          margin: 5px 0;
        }

        ul {
          margin: 14px 0;

          li {
            margin-bottom: 4px;
            font-size: 12px;
          }
        }
      }
    }
  }

  @media screen and (orientation: landscape) and (min-device-width: 600px) and (max-device-width: 750px) {
    h2 {
      letter-spacing: 4px;
      font-size: 49px;
    }
  }

  @media screen and (orientation: landscape) and (min-device-width: 1000px) and (max-device-width: 1200px) {
    h2 {
      letter-spacing: 14px !important;
      font-size: 56px !important;
      margin-bottom: -5.2vw !important;
    }
  }

  @media ${device.tablet} {
    h2 {
      font-size: 55px;
      line-height: 72px;
      letter-spacing: 10px;

      &:before {
        bottom: -14px;
        left: -11%;
        width: 165px;
        height: 56px;
      }
    }

    div.proc-wrapper {
      div.thumbs-wrapper {
        div.thumb {
          margin: 0 0 25px 0;

          div.icon {
            width: 78px;
            height: 78px;
          }

          h6 {
            font-size: 25px;
            font-weight: 400;
            margin: 5px 0;
          }

          ul {
            margin: 14px 0;

            li {
              margin-bottom: 4px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  @media ${device.laptop} {
    h2 {
      letter-spacing: 18px;
      font-weight: 200;
      font-size: 76px;
      line-height: 72px;
      margin-bottom: -4.15vw;
      margin-top: 10px;

      &:before {
        display: none;
      }
    }

    div.proc-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 80vw;

      div.img-desktop {
        display: block;

        img {
          width: 100%;
          height: 100%;
          position: absolute;
        }

        #square-g {
          width: 61.5vw;
          height: 15.77vw;
          background: #f7f5f4;
          position: absolute;
          top: -9vw;
          right: -10vw;
        }
      }

      div#img-1 {
        width: 24.38vw;
        height: 38.23vw;

        img {
          top: 3vw;
        }
      }

      div#img-2 {
        width: 41.77vw;
        height: 33.18vw;

        img {
          top: -4vw;
        }
      }

      div#thumb1 {
        width: 55.62vw;
        justify-content: space-evenly;
      }

      div#thumb2 {
        width: 34.23vw;
        justify-content: space-around;
      }

      div.thumbs-wrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        div.thumb {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 0 25px 0;

          div.icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 3.86vw;
            height: 3.86vw;

            img {
              height: 100%;
              color: #e49b86;
            }
          }

          h6 {
            font-family: 'Montserrat', sans-serif;
            text-transform: uppercase;
            font-size: 18px;
            font-weight: 400;
            margin: 5px 0;
          }

          ul {
            margin: 6px 0;

            li {
              margin-bottom: 4px;
              font-size: 11px;
            }
          }
        }
      }
    }
  }

  @media ${device.laptopL} {
    h2 {
      letter-spacing: 14px;
      font-size: 96px;
      margin-bottom: -3.8vw;
      margin-top: 10px;
    }

    div.proc-wrapper {
      div.thumbs-wrapper {
        div.thumb {
          h6 {
            font-size: 23px;
            margin: 5px 0;
          }

          ul {
            margin: 8px 0;

            li {
              margin-bottom: 6px;
              font-size: 15px;
            }
          }
        }
      }
    }
  }

  @media ${device.desktop} {
    h2 {
      letter-spacing: 18px;
      font-size: 118px;
      margin-bottom: -3.3vw;
      margin-top: 10px;
    }

    div.proc-wrapper {
      div.thumbs-wrapper {
        div.thumb {
          h6 {
            font-size: 28px;
            margin: 7px 0;
          }

          ul {
            margin: 10px 0;

            li {
              margin-bottom: 6px;
              font-size: 18px;
            }
          }
        }
      }
    }
  }

  @media ${device.desktopL} {
    h2 {
      letter-spacing: 25px;
      font-size: 154px;
      margin-bottom: -3vw;
      margin-top: 10px;
    }

    div.proc-wrapper {
      div.thumbs-wrapper {
        div.thumb {
          h6 {
            font-size: 32px;
            margin: 12px 0;
          }

          ul {
            margin: 12px 0;

            li {
              margin-bottom: 10px;
              font-size: 24px;
            }
          }
        }
      }
    }
  }
`;

export const Know = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 25px;
  margin-top: 20px;

  h2 {
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    text-align: center;
    color: #bdbdbd;
    letter-spacing: 5px;
    font-weight: 200;
    font-size: 50px;
    z-index: 1;
    line-height: 100px;
  }

  #square-h {
    position: absolute;
    top: 0;
    left: -7%;
    width: 90px;
    height: 100px;
    background: #5b3f31;
    display: inline-block;
    z-index: 0;
  }

  div.contact-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 86%;

    div.text {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      h6 {
        font-family: 'Montserrat', sans-serif;
        font-weight: 200;
        width: 212px;
        font-size: 26px;
        z-index: 1;
        margin: 0 10%;
        text-transform: uppercase;
        text-align: center;
        line-height: 32px;
        margin: -10px 0 14px 0;
      }

      p {
        text-align: center;
        font-style: italic;
        width: 70%;
        font-size: 13px;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin: 20px 0;

      input[type='text'],
      input[type='e-mail'],
      input[type='tel'] {
        width: 100%;
        height: 20px;
        font-size: 13px;
        border: none;
        border-bottom: 0.5px solid #bc8966;
        padding: 12px 0;
        color: #5e5e5e;
        font-style: italic;
        margin-bottom: 24px;

        &::placeholder {
          color: #7e7e7e;
          font-style: italic;
        }
      }

      span {
        color: #e60000;
        align-self: flex-start;
        margin: -18px 0 10px 0;
        font-size: 10px;
        font-weight: 200;
        font-style: italic;
      }

      div.radio-buttons {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-evenly;

        label {
          display: flex;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          position: relative;
          font-style: italic;
          font-size: 13px;

          &:hover {
            span.checkmark {
              background-color: #aaa;

              color: #7e7e7e;
              margin: 0 12px 0 0;
              font-weight: normal;
              font-style: italic;
              font-size: 13px;
            }
          }

          input[type='radio'] {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }

          span.checkmark {
            height: 17px;
            width: 17px;
            background-color: #ccc;
            border-radius: 50%;

            color: #7e7e7e;
            margin: 0 12px 0 0;
            font-weight: normal;
            font-style: italic;
            font-size: 13px;

            &:after {
              content: '';
              position: absolute;
              display: none;
              top: 5px;
              left: 5px;
              width: 7px;
              height: 7px;
              border-radius: 50%;
              background: white;
            }
          }

          input:checked ~ .checkmark {
            background-color: #bf846a;

            &:after {
              display: block;
            }
          }

          span.radio-title {
            /* white-space: normal; */

            color: #7e7e7e;
            margin: 0;
            font-weight: normal;
            font-style: italic;
            font-size: 13px;
          }
        }
      }

      button {
        width: 100%;
        padding: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background: #bf846a;
        text-transform: uppercase;
        font-size: 18px;
        opacity: 1;
        margin-top: 22px;

        &:hover {
          opacity: 0.8;
        }
      }

      div.data-warning {
        width: 100%;
        display: flex;
        align-items: flex-start;
        margin: 15px 0;

        p {
          font-size: 0.7rem;
          font-style: italic;
        }
      }
    }
  }

  #square-i {
    display: none;
  }

  @media ${device.tablet} {
    h2 {
      letter-spacing: 10px;
      font-size: 80px;
      line-height: 150px;
    }

    #square-h {
      height: 150px;
      width: 120px;
    }

    div.contact-wrapper {
      div.text {
        h6 {
          font-size: 28px;
          margin: -10px 0 22px 0;
        }

        p {
          font-size: 15px;
        }
      }

      form {
        input {
          font-size: 14px;
        }

        button {
          font-size: 15px;
          padding: 17px;
        }
      }
    }
  }

  @media ${device.laptop} {
    align-items: flex-start;
    margin: 12px 0 3vw 0;

    h2 {
      margin-left: 10vw;
      letter-spacing: 14px;
    }

    #square-h {
      background: #f8e2d6;
      width: 24vw;
      height: 14vw;
      top: 70px;
    }

    div.contact-wrapper {
      flex-direction: row;
      width: 42.92vw;
      margin-left: 33.33vw;

      div.text {
        align-items: flex-start;
        width: 40%;
        margin: -36px 0 0 0;

        h6 {
          text-align: start;
          width: 160px;
          margin-top: -4.2vw;
        }

        p {
          text-align: start;
          font-size: 12px;
        }
      }

      form {
        width: 60%;
        padding: 0 1%;

        input[type='text'],
        input[type='e-mail'],
        input[type='tel'] {
          font-size: 12px;
        }

        div.radio-buttons {
          label {
            font-size: 12px;
            &:hover {
              span.checkmark {
                font-size: 12px;
              }
            }

            span.checkmark {
              font-size: 12px;
            }

            span.radio-title {
              font-size: 12px;
            }
          }
        }

        span {
          font-size: 10px;
        }

        button {
          font-size: 14px;
          padding: 10px;
        }
      }
    }

    #square-i {
      display: block;
      background: #e49b86;
      width: 6vw;
      height: 10vw;
      right: 0;
      top: 6vw;
    }
  }

  @media ${device.laptopL} {
    margin-top: 18px;
    margin-bottom: 5vw;

    h2 {
      letter-spacing: 18px;
      font-size: 110px;
      margin-bottom: 35px;
    }

    div.contact-wrapper {
      div.text {
        margin: 0 0 0 0;

        h6 {
          width: 220px;
          font-size: 38px;
          line-height: 44px;
          /* margin-top: -30px; */
        }

        p {
          font-size: 14px;
        }
      }

      form {
        input,
        div.radio-buttons label {
          font-size: 14px !important;
          line-height: 16px !important;
        }

        input {
          padding: 18px 0 !important;

          span {
            font-size: 12px;
          }
        }

        div.radio-buttons {
          label {
            font-size: 12px;
            &:hover {
              span.checkmark {
                font-size: 12px;
              }
            }

            span.checkmark {
              font-size: 12px;
            }

            span.radio-title {
              font-size: 12px;
            }
          }
        }

        span {
          font-size: 10px;
        }

        button {
          font-size: 18px;
          padding: 10px;
        }
      }
    }
  }

  @media ${device.desktop} {
    margin-top: 42px;

    h2 {
      letter-spacing: 20px;
      font-size: 140px;
      margin-bottom: 42px;
    }

    div.contact-wrapper {
      div.text {
        margin: 0 0 0 0;

        h6 {
          width: 270px;
          font-size: 48px;
          line-height: 60px;
          /* margin-top: -30px; */
        }

        p {
          font-size: 18px;
        }
      }

      form {
        input,
        div.radio-buttons label {
          font-size: 18px !important;
          line-height: 18px !important;
        }

        input {
          padding: 20px 0 !important;
          margin-bottom: 30px !important;
        }

        div.radio-buttons {
          label {
            font-size: 18px;
            &:hover {
              span.checkmark {
                font-size: 18px;
              }
            }

            span.checkmark {
              font-size: 18px;
            }

            span.radio-title {
              font-size: 18px;
            }
          }
        }

        span {
          font-size: 14px;
        }

        button {
          font-size: 25px;
          padding: 14px;
          margin-top: 28px;
        }
      }
    }
  }

  @media ${device.desktopL} {
    margin-top: 88px;

    h2 {
      letter-spacing: 25px;
      font-size: 160px;
      margin-bottom: 100px;
    }

    div.contact-wrapper {
      div.text {
        margin: 0 0 0 0;

        h6 {
          width: 270px;
          font-size: 64px;
          line-height: 83px;
          /* margin-top: -30px; */
        }

        p {
          font-size: 22px;
          line-height: 33px;
        }
      }

      form {
        input,
        div.radio-buttons label {
          font-size: 22px !important;
          line-height: 18px !important;
        }

        input {
          padding: 24px 0 !important;
          margin-bottom: 38px !important;
        }

        div.radio-buttons {
          label {
            font-size: 22px;
            &:hover {
              span.checkmark {
                font-size: 22px;
              }
            }

            span.checkmark {
              font-size: 22px;
            }

            span.radio-title {
              font-size: 22px;
            }
          }
        }

        span {
          font-size: 18px;
        }

        button {
          font-size: 31px;
          padding: 20px;
          margin-top: 38px;
        }
      }
    }
  }
`;

export const Where = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  h2 {
    font-size: 50px;
    letter-spacing: 5px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
    text-transform: uppercase;
    color: #bdbdbd;
    line-height: 1px;
    z-index: 1;
    margin: 30px -12px 0 0;
  }

  div.where-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f7f1e3;
    padding: 65px 0 50px 0;

    div.text {
      display: flex;
      flex-direction: column;
      align-items: center;

      div.location {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        h3 {
          font-family: 'Montserrat', sans-serif;
          text-transform: uppercase;
          font-weight: 200;
          font-size: 14px;
          margin-bottom: 10px;
        }

        ul {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          li {
            font-size: 11px;
          }
        }

        a {
          text-decoration: none;
          color: #7e7e7e;

          &:hover {
            color: #5e5e5e;
          }

          ul.adress {
            margin: 12px 0;
          }
        }

        ul.phones li i.fa-whatsapp {
          color: #c5755c;
          font-size: 10px;
        }
      }

      div#first-location {
        margin-bottom: 50px;
      }

      div#second-location {
        margin-bottom: 50px;
      }
    }

    a.desktop-contact {
      display: none;
    }
  }

  div.mobile {
    width: 100%;
    position: relative;

    a.mobile-contact {
      text-decoration: none;
      color: #7e7e7e;
      font-size: 10px;
      position: absolute;
      top: 8vw;
      left: 14vw;

      span {
        color: #c5755c;
      }
    }
  }

  div.where-img {
    display: block;
    position: static;
    width: 120px;
    height: 172px;
    background-image: url(${Feet});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 20%;
    margin: -100px -70vw 0 0;
  }

  @media screen and (orientation: landscape) and (min-device-width: 1000px) and (max-device-width: 1200px) {
    div.where-wrapper {
      div.text {
        margin: 30px 0 !important;

        div#first-location {
          margin-right: 40px !important;
        }
      }
    }
  }

  @media ${device.tablet} {
    h2 {
      font-size: 80px;
    }

    div.where-wrapper {
      div.text {
        div.location {
          ul {
            li {
              font-size: 12px;
            }
          }

          ul.phones li i.fa-whatsapp {
            font-size: 11px;
          }
        }
      }
    }

    div.mobile {
      a.mobile-contact {
        font-size: 11px;
        top: 9vw;
        left: 20vw;
      }
    }

    div.where-img {
      width: 210px;
      height: 247px;
      margin: -100px -74vw 0 0;
    }
  }

  @media ${device.laptop} {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    h2 {
      font-size: 80px;
      letter-spacing: 14px;
      margin: 30px 24.5vw 0 0;
      z-index: 2;
    }

    div.where-wrapper {
      width: 73.5vw;
      height: 33vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 6vw;
      z-index: 1;

      div.text {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 96px 30px;

        div.location {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          h3 {
            font-family: 'Montserrat', sans-serif;
            text-transform: uppercase;
            font-weight: 200;
            font-size: 16px;
            margin-bottom: 10px;
          }

          ul {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            li {
              font-size: 11px;
            }
          }

          ul.adress {
            margin: 12px 0;
          }

          ul.phones li i.fa-whatsapp {
            color: #c5755c;
            font-size: 10px;
          }
        }

        div#first-location,
        div#second-location {
          margin-bottom: 0px;
          margin-right: 15px;
        }
      }

      a.desktop-contact {
        display: block;
        font-size: 12px;
        text-decoration: none;
        color: #7e7e7e;

        span {
          color: #c5755c;
        }
      }
    }

    div.mobile {
      display: none;
    }

    div.where-img {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 38vw;
      height: 31vw;
      margin: 0;
    }
  }

  @media ${device.laptopL} {
    h2 {
      font-size: 110px;
      letter-spacing: 18px;
    }

    div.where-wrapper {
      div.text {
        div.location {
          h3 {
            font-size: 18px;
            margin-bottom: 12px;
          }

          ul {
            li {
              font-size: 14px;
            }
          }

          ul.adress {
            margin: 14px 0;
          }

          ul.phones li i.fa-whatsapp {
            color: #c5755c;
            font-size: 12px;
          }
        }
      }

      a.desktop-contact {
        font-size: 14px;
      }
    }
  }

  @media ${device.desktop} {
    h2 {
      font-size: 140px;
      letter-spacing: 20px;
    }

    div.where-wrapper {
      div.text {
        margin: 122px 0;

        div.location {
          h3 {
            font-size: 24px;
            margin-bottom: 18px;
          }

          ul {
            li {
              font-size: 18px;
            }
          }

          ul.adress {
            margin: 18px 0;
          }

          ul.phones li i.fa-whatsapp {
            color: #c5755c;
            font-size: 16px;
          }
        }
      }

      a.desktop-contact {
        font-size: 18px;
      }
    }
  }

  @media ${device.desktopL} {
    h2 {
      font-size: 160px;
      letter-spacing: 25px;
    }

    div.where-wrapper {
      div.text {
        margin: 170px 0;

        div.location {
          margin-right: 20px;

          h3 {
            font-size: 28px;
            margin-bottom: 22px;
          }

          ul {
            li {
              font-size: 22px;
            }
          }

          ul.adress {
            margin: 22px 0;
          }

          ul.phones li i.fa-whatsapp {
            color: #c5755c;
            font-size: 20px;
          }
        }
      }

      a.desktop-contact {
        font-size: 22px;
      }
    }
  }
`;
