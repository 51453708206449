import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #bf846a;
  color: #fff;
  padding: 40px 0;

  h1 {
    font-size: 42px;
  }

  p {
    text-transform: uppercase;
    font-size: 17px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 700px;
  margin: 40px auto 0;

  ul {
    align-items: flex-start;
  }

  h1 {
    font-weight: bold;
    font-size: 32px;
    color: #999999;
    margin: 30px 0;
  }

  hr {
    height: 1px;
    border: 0;
    margin-bottom: 20px;
    background: #e7e7e7;
  }

  form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    div.wrapper.details {
      width: 75%;
    }

    div.wrapper {
      label.title {
        display: flex;
        flex-direction: column;
        color: #999999;
        margin-bottom: 20px;
        font-weight: bold;
      }

      input[type='text'] {
        padding: 5px;
        margin-top: 4px;
        font-family: sans-serif;
        color: #666666;
        border-radius: 4px;
        border: 1px solid #e7e7e7;
      }

      div.radio-buttons {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-evenly;
        margin-bottom: 16px;

        label {
          display: flex;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          position: relative;
          font-size: 13px;
          margin-bottom: 5px;

          &:hover {
            span.checkmark {
              background-color: #999999;

              color: #999999;
              margin: 0 6px 0 0;
              font-weight: normal;
              font-size: 13px;
            }
          }

          input[type='radio'] {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }

          span.checkmark {
            height: 17px;
            width: 17px;
            background-color: #999999;
            border-radius: 50%;

            color: #999999;
            margin: 0 6px 0 0;
            font-weight: bold;
            font-size: 13px;

            &:after {
              content: '';
              position: absolute;
              display: none;
              top: 5px;
              left: 5px;
              width: 7px;
              height: 7px;
              border-radius: 50%;
              background: white;
            }
          }

          input:checked ~ .checkmark {
            background-color: #bf846a;

            &:after {
              display: block;
            }
          }

          span.radio-title {
            /* white-space: normal; */

            color: #999999;
            margin: 0;
            font-weight: bold;
            font-size: 13px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  p.result {
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    font-weight: bold;
    padding: ${props => (props.result !== null ? '10px' : 0)};
    border-radius: 4px;
    margin-top: 10px;
  }
`;

export const PostImage = styled.div`
  align-self: center;
  margin-bottom: 30px;
  position: relative;

  label {
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    img {
      height: 120px;
      width: 120px;
      border-radius: 50%;
      border: 3px solid #e7e7e7;
      background: #eee;
      object-fit: cover;
    }

    input {
      display: none;
    }
  }
`;

export const EditorMalu = styled.div`
  background: #ffffff;
  border-radius: 4px;

  .megadraft-editor .DraftEditor-root,
  .megadraft-editor .toolbar {
    font-family: 'Merriweather', serif;
    color: #999999;
    font-size: 0.8rem;
    line-height: 1.1rem;
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .megadraft-editor .paragraph + .paragraph {
    margin-top: 0px;
  }

  .megadraft-editor .public-DraftEditor-content figure {
    margin: 0px 0;
  }

  .megadraft-editor .public-DraftStyleDefault-ol,
  .megadraft-editor .public-DraftStyleDefault-ul {
    line-height: 1rem;
    margin: 0px 0 0px 0;
    padding-left: 40px;
    align-items: flex-start;
  }

  .megadraft-editor blockquote {
    border-left: 2px solid #ddd;
    color: #999;
    font-style: italic;
    margin: 6px 0 6px 0;
    padding: 6px 0 5px 18px;
  }

  .megadraft-editor .paragraph + blockquote {
    margin-top: 0px;
  }

  .megadraft-editor h2 {
    font-family: 'Montserrat', Sans-serif;
    font-weight: 200;
    font-size: 1.7rem;
    letter-spacing: -0.056rem;
    line-height: 2rem;
    margin: 10px 0 10px 0;
    color: #777777;
  }

  .megadraft-editor h2:first-child {
    margin-top: 0;
  }

  .megadraft-editor .sidemenu__button {
    cursor: default;
  }

  .megadraft-editor .sidemenu__button--open {
    transform: rotate(0deg);
  }

  .megadraft-editor .sidemenu__button--open:before {
    background-color: #181818;
  }
`;

export const Save = styled.button`
  font-family: 'Roboto', sans-serif;
  border: 0;
  background: #bf846a;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  /* width: 100px; */
  margin-top: 20px;
  box-shadow: 0 16px 12px -12px rgba(191, 132, 106, 0.7);
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.9;
  }
`;

export const Cancel = styled(Link)`
  font-family: 'Roboto', sans-serif;
  border: 0;
  background: #ee4540;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  /* width: 100px; */
  margin-top: 8px;
  box-shadow: 0 16px 12px -12px rgba(191, 132, 106, 0.7);
  transition: opacity 0.3s ease;
  color: #ffffff;
  font-size: 14px;

  &:hover {
    opacity: 0.9;
  }
`;
