import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    height: 100%;
    max-width: 100%;
    overflow-x: hidden;
    /* position: relative; */
  }

  body {
    background: #ffffff;
    -webkit-font-smoothing: antialiased !important;
  }

  body, input {
    color: #7e7e7e;
    font-size: 13px;
    font-family: 'Merriweather', serif;
  }

  a, button {
    cursor: pointer;
  }

  button {
    color: #ffffff;
    font-size: 14px;
    font-family: 'Montserrat', serif;
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .square {
    position: absolute;
  }

  a {
    text-decoration: none;
  }
`;
