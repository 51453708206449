import styled from 'styled-components';

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #bf846a;
  color: #fff;
  padding: 40px 0;

  h1 {
    font-size: 42px;
  }

  p {
    text-transform: uppercase;
    font-size: 17px;
  }
`;

export const Container = styled.div`
  max-width: 700px;
  margin: 50px auto 0;

  form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    input {
      font-family: 'Roboto', sans-serif;
      background: #fff;
      border: 1px solid #e7e7e7;
      border-radius: 4px;
      height: 44px;
      padding: 0 15px;
      color: #666666;
      margin: 0 0 10px;

      &::placeholder {
        color: rgba(0, 0, 0, 0.3);
      }
    }

    span {
      color: #fb6f91;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    }

    hr {
      border: 0;
      height: 1px;
      background: #e7e7e7;
      margin: 10px 0 20px;
    }

    button {
      font-family: 'Roboto', sans-serif;
      margin: 5px 0 0;
      height: 44px;
      background: #bf846a;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 14px;
      transition: opacity 0.3s ease;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  button {
    font-family: 'Roboto', sans-serif;
    margin: 5px 0 0;
    height: 44px;
    background: #ee4540;
    font-weight: bold;
    color: #fff;
    border: 0;
    border-radius: 4px;
    font-size: 14px;
    transition: opacity 0.3s ease;
    cursor: pointer;
    width: 100%;

    &:hover {
      opacity: 0.8;
    }
  }
`;
