import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FaInstagram } from 'react-icons/fa';
import { Container, Wrapper, Logo, NavWrapper, Instagram } from './styles';

import MenuItems from '../MenuItems';

class Menu extends Component {
  static propTypes = {
    menu: PropTypes.shape({
      menuOpen: PropTypes.bool,
      section: PropTypes.string.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  handleMenuItemClick = () => {
    const { menu, dispatch } = this.props;

    dispatch({
      type: 'TOGGLE_MENU',
      menuOpen: menu.menuOpen,
    });
  };

  scrollMain = () => {
    const { dispatch } = this.props;

    dispatch({
      type: 'SCROLL_MAIN_PAGE',
      section: 'top',
    });
  };

  render() {
    const menuNav = [
      {
        id: 0,
        name: 'Sou',
        url: 'sou',
      },
      {
        id: 1,
        name: 'Como?',
        url: 'como',
      },
      {
        id: 2,
        name: 'Procedimentos',
        url: 'procedimentos',
      },
      {
        id: 3,
        name: 'Conheça',
        url: 'conheca',
      },
      {
        id: 4,
        name: 'Onde?',
        url: 'onde',
      },
    ];

    const { menu } = this.props;

    return (
      <Container menuOpen={menu.menuOpen}>
        {menu.menuOpen ? (
          <Wrapper>
            <section>
              <div className="logo-wrapper">
                <Logo
                  to="/"
                  onClick={() => {
                    this.handleMenuItemClick();
                    this.scrollMain();
                  }}
                />
              </div>

              <div className="division">
                <NavWrapper>
                  {menuNav.map(item => (
                    <MenuItems key={item.id} url={item.url}>
                      {item.name}
                    </MenuItems>
                  ))}

                  <div className="little-square" />

                  <a
                    className="blog-link"
                    href="/blog"
                    onClick={() => {
                      this.handleMenuItemClick();
                    }}
                  >
                    Sua Pele
                  </a>
                </NavWrapper>

                <Instagram
                  as="a"
                  href="https://instagram.com/malu.dabronzo?igshid=u3iei12wfw2v"
                  target="_blank"
                >
                  <FaInstagram className="instagram" />
                </Instagram>

                <a
                  className="sbd"
                  href="https://www.sbd.org.br"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sociedade Brasileira de Dermatologia
                </a>
              </div>
            </section>
          </Wrapper>
        ) : null}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  menu: state.menu,
});

export default connect(mapStateToProps)(Menu);
