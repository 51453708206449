import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MegadraftEditor, editorStateFromRaw } from 'megadraft';
import api from '~/services/api';
// import { Editor, ConvertFromRaw } from 'draft-js';

import {
  Wrapper,
  Purpose,
  Container,
  Content,
  Cover,
  BackDesktop,
  Text,
  SocialDesktop,
} from './styles';
import Sidebar from '~/components/SidebarBlog';

import PurposeLogo from '~/assets/logos/purpose.svg';
import FbLogo from '~/assets/images/fb.svg';
import TwitterLogo from '~/assets/images/twitter.svg';

// const post = {
//   _id: 0,
//   title: 'Lorem Ipsum',
//   image:
//     'https://d39l2hkdp2esp1.cloudfront.net/img/photo/154559/154559_00_2x.jpg',
//   date: 'dia 21 de agosto, às 0:06h',
// };

// const storedState = ConvertFromRaw(JSON.parse(post.content));

export default function Post({ match }) {
  const [post, setPost] = useState([]);

  useEffect(() => {
    async function fecthData() {
      const wordDiscardIndex = match.params.id.indexOf('-');
      const id = match.params.id.substring(0, wordDiscardIndex);

      const response = await api.get(`/post/${id}`);

      const postContent = JSON.parse(JSON.parse(response.data.content));

      const postData = response.data;
      postData.content = postContent;

      setPost(postData);
    }

    fecthData();
  }, [match]);

  return (
    <Wrapper>
      <Purpose>
        <a
          href="http://www.wearepurpose.com.br"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={PurposeLogo} alt="Purpose" />
        </a>
      </Purpose>

      <Container>
        <h1 className="title-desktop">SUA PELE</h1>
        <Content>
          <Cover>
            <img src={post.url} alt="Post cover" />
          </Cover>

          <Text>
            <BackDesktop>
              <a href="/blog" className="back-arrow">
                <span className="back-line top" />
                <span className="back-line bottom" />
              </a>

              <p>{post.date}</p>
            </BackDesktop>

            <h1>{post.title}</h1>

            <div className="social-mobile">
              <a
                href={`https://www.facebook.com/sharer.php?u=http%3A%2F%2Fmaludabronzo.com.br%2Fpost%2F${post._id}-post%2F`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* <a
                href="https://www.facebook.com/sharer.php?u=http%3A%2F%2Fthevie.com.br%2F"
                target="_blank"
                rel="noopener noreferrer"
              > */}
                <img src={FbLogo} alt="Facebook" />
              </a>
              <a
                href={`https://twitter.com/intent/tweet?url=http%3A%2F%2Fmaludabronzo.com.br%2Fpost%2F${post._id}-post%2F&text=Confira+novas+dicas+de+dermatologia+-+&hashtags=dermatologia,maludabronzo`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* <a
                href="https://twitter.com/intent/tweet?url=http%3A%2F%2Fcss-tricks.com%2F&text=Tips%2C+Tricks%2C+and+Techniques+on+using+Cascading+Style+Sheets.&hashtags=css,html"
                target="_blank"
                rel="noopener noreferrer"
              > */}
                <img src={TwitterLogo} alt="Twitter" />
              </a>
            </div>

            <MegadraftEditor
              editorState={editorStateFromRaw(post.content)}
              readOnly
            />

            <SocialDesktop>
              <hr />

              <div className="social-desktop">
                <a
                  href={`https://www.facebook.com/sharer.php?u=http%3A%2F%2Fmaludabronzo.com.br%2Fpost%2F${post._id}-post%2F`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={FbLogo} alt="Facebook" />
                </a>
                <a
                  href={`https://twitter.com/intent/tweet?url=http%3A%2F%2Fmaludabronzo.com.br%2Fpost%2F${post._id}-post%2F&text=Confira+novas+dicas+de+dermatologia+-+&hashtags=dermatologia,maludabronzo`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={TwitterLogo} alt="Twitter" />
                </a>
              </div>
            </SocialDesktop>
          </Text>
        </Content>

        <Sidebar />
      </Container>
    </Wrapper>
  );
}

Post.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    isExact: PropTypes.bool.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
